import {
  AnimatedList,
  ErrorCard,
  StatusCard,
  StyleException,
  styled,
  topbarHeight,
  topbarHeightMedium,
  zIndex,
} from '@kivra/react-components';
import React, { createContext, useContext, useState } from 'react';

export type ServiceMessageData = {
  title?: string;
  body: string;
  variant: 'success' | 'info' | 'error';
  id: string;
  fadeout?: boolean;
};

type ServiceMessageContext = {
  addServiceMessage: (message: Omit<ServiceMessageData, 'id'>) => void;
};

const ServiceMessageContext = createContext({
  addServiceMessage: (message: Omit<ServiceMessageData, 'id'>) => {
    console.error('addServiceMessage not implemented', message);
  },
});
export const useServiceMessage = (): ServiceMessageContext =>
  useContext(ServiceMessageContext);

interface Props {
  children: React.ReactNode;
  getCopy: (args: 'sender_portal__general_close') => string;
}

export const ServiceMessageProvider = ({
  getCopy,
  children,
}: Props): React.JSX.Element => {
  const [messages, setMessages] = useState<ServiceMessageData[]>([]);

  const addMessage = (
    message: Omit<ServiceMessageData, 'id'>
  ): (() => void) => {
    const id = new Date().valueOf().toString();
    setMessages([{ ...message, id }, ...messages]);
    if (message.fadeout) {
      fadeout(id);
    }

    return () => dismissMessage(id);
  };

  const dismissMessage = (messageIdToRemove: string): void => {
    setMessages(messages =>
      messages.filter(({ id }: ServiceMessageData) => id !== messageIdToRemove)
    );
  };

  const fadeout = (id: string): void => {
    setTimeout(() => dismissMessage(id), 2000);
  };

  return (
    <>
      <ServiceMessageContext.Provider value={{ addServiceMessage: addMessage }}>
        {children}
      </ServiceMessageContext.Provider>
      <ServiceMessageWrapper data-test-id="ServiceMessageContainer">
        <AnimatedList>
          {messages.map(message => {
            const props: ServiceMessageProps = { ...message };
            if (!message.fadeout) {
              props.button = {
                onClick: () => dismissMessage(message.id),
                label: getCopy('sender_portal__general_close'),
              };
            }

            return <ServiceMessage key={message.id} {...props} />;
          })}
        </AnimatedList>
      </ServiceMessageWrapper>
    </>
  );
};

type ServiceMessageProps = Omit<ServiceMessageData, 'fadeout'> & {
  button?: {
    onClick: () => void;
    label: string;
  };
};

const ServiceMessage = ({
  title,
  body,
  variant,
  id,
  button,
}: ServiceMessageProps): React.JSX.Element => {
  return variant === 'error' ? (
    <ErrorCard severity="high" key={id}>
      {title && <ErrorCard.Title>{title}</ErrorCard.Title>}
      <ErrorCard.Text>{body}</ErrorCard.Text>
      {button && (
        <ErrorCard.Button variant="secondary" onClick={button.onClick}>
          {button.label}
        </ErrorCard.Button>
      )}
    </ErrorCard>
  ) : (
    <StatusCard
      key={id}
      variant={variant === 'info' ? 'information' : variant}
      onClose={
        button && {
          ariaLabel: button.label,
          callback: button.onClick,
        }
      }
    >
      {title && <StatusCard.Title>{title}</StatusCard.Title>}
      <StatusCard.Text>{body}</StatusCard.Text>
    </StatusCard>
  );
};

const ServiceMessageWrapper = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  pointerEvents: 'none',
  position: 'fixed',
  top: `${topbarHeight}px`,
  left: 0,
  right: 0,
  padding: [0, '$spacing-32'],
  zIndex: zIndex.snackbar,
  $medium: {
    top: `${topbarHeightMedium}px`,
  },
  '& > *': {
    width: '100%',
    maxWidth: 1200,
    margin: StyleException('4px 0 !important'),
    pointerEvents: 'all',
  },
});
