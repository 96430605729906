import {
  Button,
  Caption,
  Loader,
  Margin,
  styled,
} from '@kivra/react-components';
import { CrossIcon } from '@kivra/react-components/icons';
import React from 'react';

interface Props {
  label: string;
  status: 'loading' | 'complete';
  onClose?: () => void;
}

export const StatusArea = ({
  status,
  label,
  onClose,
}: Props): React.JSX.Element => {
  const labelElement =
    status === 'complete' ? (
      <Caption>{label}</Caption>
    ) : (
      <Caption style={{ fontStyle: 'italic' }}>{label}</Caption>
    );
  const loaderElement = status === 'loading' && (
    <div className="spinner">
      <Loader size="small" />
    </div>
  );
  const closeElement = onClose && <CrossIcon size={16} className="close" />;

  return (
    <StyledButton onClick={onClose} variant="secondary" disabled={!onClose}>
      <Margin right={8}>{labelElement}</Margin>
      {loaderElement}
      {closeElement}
    </StyledButton>
  );
};

/**
 * If the Button contains a .spinner, it should visually be replaced
 * be .close on hover and keyboard focus. If there is no .spinner,
 * .close is always displayed.
 */
const StyledButton = styled(Button)({
  '& .spinner + .close': {
    display: 'none',
  },
  '&:hover, .keyboardFocus &:focus': {
    '.spinner': {
      display: 'none',
    },
    '.close': {
      display: 'block',
    },
  },
});
