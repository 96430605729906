import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  useCampaignStatus,
  useCampaignType,
} from '../../../../../context/campaignContext';
import type {
  CampaignForm,
  CampaignFormErrors,
} from '../../../../../types/campaignForm';
import type { DateError } from '../../../../../types/dateError';
import { isOngoing } from '../../../../../util/campaignStatus';
import { getCopy } from '../../../../../util/copy';
import { pluralDocumentTypeCopy } from '../../../../../util/copyKeys';
import {
  isActiveFromBeforeAttachFrom,
  isActiveFromBeforeToday,
  isActiveToBeforeActiveFrom,
  isActiveToBeforeAttachTo,
  isAttachToBeforeAttachFrom,
  isInThePast,
} from '../../../../../util/dates';
import { DateRangePicker } from '../DateRangePicker';
import { FormErrorMessage } from '../FormErrorMessage';
import { Block } from '../block/Block';

const dateValidationError: Record<string, DateError> = {
  activeToBeforeActiveFrom: {
    copyKey: 'campaigns__date_error_to_before_from',
    inputIds: ['activeTo', 'activeFrom'],
  },
  attachToBeforeAttachFrom: {
    copyKey: 'campaigns__date_error_to_before_from',
    inputIds: ['attachTo', 'attachFrom'],
  },
  activeToBeforeAttachTo: {
    copyKey: 'campaigns__date_error__active_to_before_attach_to',
    inputIds: ['activeTo', 'attachTo'],
  },
  activeFromBeforeAttachFrom: {
    copyKey: 'campaigns__date_error__active_from_before_attach_from',
    inputIds: ['activeFrom', 'attachFrom'],
  },
  activeFromBeforeToday: {
    copyKey: 'campaigns__date_error__active_from_before_today',
    inputIds: ['activeFrom'],
  },
};

export const CampaignDatesBlocks = (): React.JSX.Element => {
  const {
    control,
    watch,
    trigger,
    getValues,
    formState,
    register,
    unregister,
  } = useFormContext<CampaignForm>();
  const { dates, submitActionType } = watch();
  const { campaignType, isTagCampaign } = useCampaignType();
  const campaignStatus = useCampaignStatus();

  useEffect(() => {
    if (isTagCampaign) {
      unregister('dates.attachFrom');
      unregister('dates.attachTo');
    } else {
      register('dates.attachFrom');
      register('dates.attachTo');
    }
  }, [campaignType]);

  const isOngoingCampaign = isOngoing(campaignStatus);

  const errorType = (formState.errors as CampaignFormErrors | undefined)?.dates
    ?.type;
  const errorObj = errorType && dateValidationError[errorType];

  const disableAttachField =
    isOngoingCampaign && !!dates.attachTo && !!isInThePast(dates.attachTo);

  return (
    <Controller
      control={control}
      name="dates"
      rules={{
        validate: {
          activeToBeforeActiveFrom: value => !isActiveToBeforeActiveFrom(value),
          attachToBeforeAttachFrom: value => !isAttachToBeforeAttachFrom(value),
          activeFromBeforeToday: value =>
            isOngoingCampaign || !isActiveFromBeforeToday(value),
          activeToBeforeAttachTo: value => !isActiveToBeforeAttachTo(value),
          activeFromBeforeAttachFrom: value =>
            !isActiveFromBeforeAttachFrom(value),
          required: value => {
            if (submitActionType === 'save') {
              return true;
            }
            const impactedFieldsAsCommaSeparatedString =
              Object.values(value).every(value => !!value) ||
              Object.entries(value)
                .filter(([_, value]) => !value)
                .map(([key]) => key)
                .join(',');
            return impactedFieldsAsCommaSeparatedString;
          },
        },
      }}
      render={props => {
        const showError = (fieldId: keyof CampaignForm['dates']): boolean =>
          Boolean(errorObj?.inputIds.includes(fieldId)) ||
          Boolean(
            submitActionType === 'publish' && !props.field.value[fieldId]
          );

        const getErrorMessage = (
          dateRangeField: 'attach' | 'active'
        ): string | undefined => {
          const hasError = errorObj?.inputIds.some(input =>
            input.startsWith(dateRangeField)
          );
          return hasError && errorObj?.copyKey
            ? getCopy(errorObj.copyKey)
            : undefined;
        };
        const attachErrorMessage = getErrorMessage('attach');
        const activeErrorMessage = getErrorMessage('active');

        const documentTypeText = pluralDocumentTypeCopy(
          getValues().documentType
        ).toLowerCase();

        return (
          <>
            {campaignType !== 'by-tag' && (
              <Block.Wrapper
                label={getCopy('campaigns__attach_label', {
                  documentType: documentTypeText,
                })}
                tooltipText={getCopy('campaigns__date_range_times_tooltip')}
              >
                <DateRangePicker
                  disabled={disableAttachField}
                  fromDate={props.field.value.attachFrom}
                  fromDateDisabled={isOngoingCampaign}
                  toDateDisablePast={isOngoingCampaign}
                  fromError={showError('attachFrom')}
                  onChange={(type: 'from' | 'to', date: Date | null) => {
                    if (type === 'from') {
                      props.field.onChange({
                        ...props.field.value,
                        attachFrom: date,
                      });
                    } else {
                      props.field.onChange({
                        ...props.field.value,
                        attachTo: date,
                      });
                    }
                    void trigger('dates');
                  }}
                  toDate={props.field.value.attachTo}
                  toError={showError('attachTo')}
                />
                {attachErrorMessage && (
                  <FormErrorMessage errorMessage={attachErrorMessage} />
                )}
              </Block.Wrapper>
            )}
            <Block.Wrapper
              label={getCopy('campaigns__active_label')}
              tooltipText={`${getCopy('campaigns__datepicker_tooltip', {
                documentType: documentTypeText,
              })}\n\n${getCopy('campaigns__date_range_times_tooltip')}`}
            >
              <>
                <DateRangePicker
                  fromDate={props.field.value.activeFrom}
                  fromDateDisabled={isOngoingCampaign}
                  fromDateDisablePast={!isOngoingCampaign}
                  fromError={showError('activeFrom')}
                  onChange={(type: 'from' | 'to', date: Date | null) => {
                    if (type === 'from') {
                      props.field.onChange({
                        ...props.field.value,
                        activeFrom: date,
                      });
                    } else {
                      props.field.onChange({
                        ...props.field.value,
                        activeTo: date,
                      });
                    }
                    void trigger('dates');
                  }}
                  minFromDate={props.field.value.attachFrom}
                  toDate={props.field.value.activeTo}
                  toDateDisablePast={true}
                  toError={showError('activeTo')}
                />
                {activeErrorMessage && (
                  <FormErrorMessage errorMessage={activeErrorMessage} />
                )}
              </>
            </Block.Wrapper>
          </>
        );
      }}
    />
  );
};
