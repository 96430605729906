import { Button, Caption, Flex, Margin, styled } from '@kivra/react-components';
import {
  CrossIcon,
  LogoutIcon,
  SenderIcon,
} from '@kivra/react-components/icons';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useAuthenticatedUser } from '../../context/globalContext';
import { useOrganizationIds } from '../../context/organizationsContext';
import {
  useCurrentOrganization,
  useSenders,
} from '../../context/sendersContext';
import { getRoute, goTo } from '../../routes/history';
import { logoutUser } from '../../util/authentication';
import { getCopy } from '../../util/copy';
import { InformationSurface } from '../InformationSurface';
import { ListItem } from './ListItem';
import { Senders } from './Senders';

interface Props {
  topElement: React.JSX.Element;
  onCloseDrawer: () => void;
  onSenderSelected: (senderKey: string) => void;
}

export const DrawerContent = ({
  onCloseDrawer,
  onSenderSelected,
  topElement,
}: Props): React.JSX.Element => {
  const { email } = useAuthenticatedUser();
  const { organizationIds } = useOrganizationIds();
  const { isAdminOrganization, organizationId } = useCurrentOrganization();
  const { params, path } = useRouteMatch<{ senderKey?: string }>();
  const senders = useSenders();
  const userHasSeveralOrganizations = organizationIds.length > 1;

  const logoutHandler = async (): Promise<void> => {
    logoutUser();
  };

  const activeSenderKey = params.senderKey;
  const isOverview = path.includes('overview');

  const loggedInAsText = `${getCopy('campaigns__logged_in_as')} ${email}`;
  return (
    <Flex
      style={{
        height: '100%',
      }}
      direction="column"
      justifyContent="space-between"
    >
      <div>
        <DrawerHeader>
          {topElement}
          <Button variant="link" onClick={onCloseDrawer}>
            <Button.Icon iconComponent={CrossIcon} color="$text-secondary" />
          </Button>
        </DrawerHeader>
        <Margin all={8}>
          <InformationSurface>
            <Caption align="center">{loggedInAsText}</Caption>
          </InformationSurface>
        </Margin>
        {(senders.length > 1 || isAdminOrganization) && (
          <ListItem
            onClick={
              isOverview
                ? undefined
                : () => goTo(getRoute({ id: 'ongoing', organizationId }))
            }
            isActive={isOverview}
          >
            {getCopy('campaigns__overview_all_tenants')}
          </ListItem>
        )}
        {!isAdminOrganization && (
          <>
            <Senders
              type="tenant"
              onSenderSelected={onSenderSelected}
              activeSenderKey={activeSenderKey}
            />
            <Senders
              type="store"
              onSenderSelected={onSenderSelected}
              activeSenderKey={activeSenderKey}
            />
          </>
        )}
      </div>
      <LogoutContainer>
        {userHasSeveralOrganizations && (
          <Button
            variant="link"
            onClick={() => goTo(getRoute({ id: 'select-organization' }))}
            size="small"
          >
            <Button.Icon iconComponent={SenderIcon} color="$text-primary" />
            {getCopy('campaigns__change_organization')}
          </Button>
        )}
        <Button variant="link" onClick={logoutHandler} size="small">
          <Button.Icon iconComponent={LogoutIcon} color="$text-primary" />
          {getCopy('misc_option__logout')}
        </Button>
      </LogoutContainer>
    </Flex>
  );
};

const DrawerHeader = styled.div({
  alignItems: 'center',
  borderBottom: `1px solid $border-distinct`,
  display: 'flex',
  height: '$responsive-topbar-height',
  justifyContent: 'space-between',
  overflow: 'hidden',
  padding: [0, '$spacing-16'],
});

const LogoutContainer = styled.div({
  backgroundColor: '$background-secondary',
  borderTop: `1px solid $border-distinct`,
  position: 'sticky',
  bottom: 0,
  boxShadow: '0px 0 10px rgba(255, 255, 255, 0.8)',
  display: 'flex',
  justifyContent: 'space-evenly',
  overflow: 'visible',
  paddingBottom: '$spacing-8',
  paddingTop: '$spacing-8',
  width: '100%',
});
