import React from 'react';
import { DatePicker, Flex, styled } from '@kivra/react-components';
import { getPageLanguage } from '@kivra/sdk/copy-consumer';
import { getCopy } from '../../../../util/copy';

interface Props {
  disabled?: boolean;
  fromDate: Date | null;
  fromDateDisabled?: boolean;
  fromDateDisablePast?: boolean;
  fromError?: boolean;
  onChange(type: 'from' | 'to', date: Date | null): void;
  minFromDate?: Date | null;
  toDate: Date | null;
  toDateDisabled?: boolean;
  toDateDisablePast?: boolean;
  toError?: boolean;
}

export const DateRangePicker = ({
  disabled,
  fromDate,
  fromDateDisabled,
  fromDateDisablePast,
  fromError,
  onChange,
  toDate,
  toDateDisabled,
  toDateDisablePast,
  toError,
  minFromDate,
}: Props): React.JSX.Element => {
  const language = getPageLanguage() || 'sv';
  return (
    <Flex>
      <DatePicker
        autoOk={true}
        disabled={disabled || fromDateDisabled}
        disablePast={fromDateDisablePast}
        language={language}
        minDate={minFromDate ?? undefined}
        errorMessage={fromError || undefined}
        format={'dd MMM yyyy'}
        value={fromDate ?? undefined}
        onChange={(date: Date | null) => {
          onChange('from', date);
        }}
        placeholder={getCopy('campaigns__from')}
        size="small"
        fullWidth
      />
      <Divider>—</Divider>
      <DatePicker
        autoOk={true}
        errorMessage={toError || undefined}
        format={'dd MMM yyyy'}
        value={toDate ?? undefined}
        disabled={disabled || toDateDisabled}
        disablePast={toDateDisablePast}
        language={language}
        onChange={(date: Date | null) => {
          onChange('to', date);
        }}
        placeholder={getCopy('campaigns__to')}
        size="small"
        fullWidth
      />
    </Flex>
  );
};

const Divider = styled('div')({
  margin: [0, '$spacing-8'],
  paddingTop: '$spacing-8',
  alignSelf: 'center',
});
