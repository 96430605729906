import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
  type ServerError,
} from '@apollo/client';
import { onError, type ErrorResponse } from '@apollo/client/link/error';
import { getPageLanguage } from '@kivra/sdk/copy-consumer';
import { userSession } from '@kivra/sdk/identity';
import { captureException } from '@kivra/sdk/log';
import type { LegitimateAny } from '@kivra/sdk/types/util';
import { isDevBuild } from '@sender-portal-shared/sdk';
import React from 'react';
import { revokeToken } from '../sdk/authentication/revokeToken';

const cache = new InMemoryCache({
  typePolicies: {
    Sender: {
      merge: true,
    },
    SenderMetrics: {
      merge: true,
    },
  },
});

interface Props {
  config: {
    sender_portal_api_origin: string;
  };
  children: React.ReactNode;
}

const errorHandler = onError(e => {
  // Strip operation before error is passed on to Sentry since the operation is too verbose and hides the actual error
  const { operation: _operation, ...cleanError } = e;

  const error = new Error(
    JSON.stringify(
      { location: window.location.href, ...cleanError },
      undefined,
      2
    )
  );

  error.name = 'SenderPortalGraphqlError';
  captureException(error);

  if (errorHasHttpStatusCode(e, 401)) {
    void revokeToken().finally(() => {
      window.location.reload();
    });
  }
});

const errorHasHttpStatusCode = (
  error: ErrorResponse,
  code: number
): boolean => {
  const { networkError, response } = error;
  return (
    (networkError as ServerError | undefined)?.statusCode == code ||
    Boolean(
      response?.errors?.some((error: unknown) => {
        return (
          (error as LegitimateAny)?.extensions?.exception?.statusCode == code
        );
      })
    )
  );
};

export const SenderPortalApolloProvider = ({
  children,
  config,
}: Props): React.JSX.Element => {
  const httpLink = new HttpLink({
    uri: `${config.sender_portal_api_origin}/v1`,
    headers: {
      authorization: `token ${userSession.getToken()}`,
      'accept-language': getPageLanguage() || 'sv',
    },
  });

  const client = new ApolloClient({
    cache,
    connectToDevTools: isDevBuild,
    link: from([errorHandler, httpLink]),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
