import type { Campaign } from '@sender-portal-fe/util-shared/src/sdk/campaigns/types/campaign';

export type CampaignType = 'receipt' | 'by-tag' | 'postal';

export function getCampaignType(campaign: Campaign): CampaignType {
  return campaign.tag
    ? 'by-tag'
    : campaign.sender.type === 'tenant'
      ? 'postal'
      : 'receipt';
}
