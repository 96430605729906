import { Button, StyleException, styled } from '@kivra/react-components';
import {
  DeviceDesktopIcon,
  DevicePhoneIcon,
} from '@kivra/react-components/icons';
import React from 'react';
import type { PreviewType } from '../../types/previewType';

interface Props {
  previewType: PreviewType;
  onDeviceSelected: (device: PreviewType) => void;
}

export const SelectDevice = ({
  onDeviceSelected,
  previewType,
}: Props): React.JSX.Element => {
  return (
    <ToggleGroup>
      <ToggleButton
        selected={previewType === 'phone'}
        onClick={() => onDeviceSelected('phone')}
      >
        <Button.Icon
          iconComponent={DevicePhoneIcon}
          color={previewType === 'phone' ? '$text-primary' : '$text-inverted'}
        />
      </ToggleButton>
      <ToggleButton
        selected={previewType === 'desktop'}
        onClick={() => onDeviceSelected('desktop')}
      >
        <Button.Icon
          iconComponent={DeviceDesktopIcon}
          color={previewType === 'desktop' ? '$text-primary' : '$text-inverted'}
        />
      </ToggleButton>
    </ToggleGroup>
  );
};

const ToggleGroup = styled('div')({
  display: 'inline-flex',
  borderRadius: '$radius-small',
  overflow: 'hidden',
});

const ToggleButton = styled(Button)<{ selected: boolean }>(({ selected }) => ({
  padding: StyleException('6px 10px'),
  minWidth: 'auto',
  minHeight: 'auto',
  backgroundColor: selected ? '$background-primary' : '!!$gray100',
  borderRadius: 0,
  pointerEvents: selected ? 'none' : 'auto',
}));
