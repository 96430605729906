import { TextField, styled } from '@kivra/react-components';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useCampaignStatus } from '../../../../../context/campaignContext';
import type { CampaignForm } from '../../../../../types/campaignForm';
import { isOngoing } from '../../../../../util/campaignStatus';
import { getCopy } from '../../../../../util/copy';
import { useConfigUrls } from '../../../../../util/useConfigUrls';
import { Block } from '../block/Block';

export const TagNameField = (): React.JSX.Element => {
  const { control, formState, watch, setValue } =
    useFormContext<CampaignForm>();
  const { readMoreUrl } = useConfigUrls();
  const campaignStatus = useCampaignStatus();
  const isDisabled = isOngoing(campaignStatus);
  const hasError = Boolean(formState.errors.tag);

  const isPublishActionType = watch('submitActionType') === 'publish';

  return (
    <Block.Wrapper
      label={getCopy('campaigns__tag_field')}
      tooltipText={getCopy('campaigns__tag_field_tooltip')}
    >
      <Controller
        control={control}
        name="tag"
        rules={{
          required: isPublishActionType,
          validate: value => {
            if (isPublishActionType) return value.trimStart().length > 0;
            return true;
          },
        }}
        render={props => (
          <TextFieldWrapper hasError={hasError}>
            <TextField
              dataTestId="CampaignTagNameField"
              size="small"
              value={props.field.value}
              onTextChange={props.field.onChange}
              onBlur={e => {
                setValue('tag', e.target.value.trimEnd());
                return props.field.onBlur;
              }}
              disabled={isDisabled}
            />
          </TextFieldWrapper>
        )}
      />
      <Block.InfoCard backgroundColor="$surface-info">
        {getCopy('campaigns__tag_segmentation_service_message')}
        <Block.InfoCardLink
          color="$text-primary"
          url={readMoreUrl}
          text={getCopy('campaigns__tag_read_more_link')}
        />
      </Block.InfoCard>
    </Block.Wrapper>
  );
};

const TextFieldWrapper = styled.div<{
  hasError: boolean;
}>(({ hasError }) => ({
  input: {
    borderColor: hasError ? '$error-text' : undefined,
  },
}));
