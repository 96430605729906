import type { ClientStatusType } from '@sender-portal-fe/util-shared/src/sdk/campaigns/types/clientStatus';

export function isOngoing(
  statusOrCampaign: ClientStatusType | { clientStatus: ClientStatusType }
): boolean {
  if (typeof statusOrCampaign === 'string') {
    return statusOrCampaign === 'ongoing';
  }
  return statusOrCampaign.clientStatus === 'ongoing';
}
