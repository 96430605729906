// This file is copied from kivra_fe_sdk repo
import {
  convertPropertyNamesToCamelCase,
  senderRequest,
} from '@kivra/sdk/common';
import { userSession } from '@kivra/sdk/identity';
import type { SenderSearchResult } from '@kivra/sdk/senders';
import type { LegitimateAny } from '@kivra/sdk/types/util/any';
import type { SearchResult } from './types/sender';

/**
 * List senders in an organization
 */
export async function getOrganizationSenders(
  orgId: string
): Promise<SenderSearchResult[]> {
  const res: LegitimateAny = await senderRequest.get({
    path: `/v1/organization/${orgId}/senders`,
    accessToken: userSession.assertSession().accessToken,
  });

  return res.map((sender: LegitimateAny) =>
    convertPropertyNamesToCamelCase<SenderSearchResult>(sender)
  );
}

/**
 * Search for senders
 * query is required
 * limit, and offset are both optional and excluded from the body if not submitted
 */
export async function searchSender(
  query: string,
  limit?: number,
  offset?: number
): Promise<SearchResult<SenderSearchResult>> {
  const addMaxCount = limit ? `&max_count=${limit}` : '';
  const addStartIndex = offset ? `&start_index=${offset}` : '';
  const path = `/v1/sender/search?query=${encodeURIComponent(query) + addMaxCount + addStartIndex}`;
  const accessToken = userSession.assertSession().accessToken;

  const result = await senderRequest.get({
    path,
    accessToken,
  });

  return convertPropertyNamesToCamelCase<SearchResult<SenderSearchResult>>(
    result as LegitimateAny
  );
}
