import { Caption, Margin, styled } from '@kivra/react-components';
import type { BannerConfig } from '@sender-portal-fe/util-shared/src/sdk/campaigns/types/bannerConfig';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getDefaultBannerOptions } from '../../../../../components/bannerEditor/defaultBannerOptions';
import { useCampaignStatus } from '../../../../../context/campaignContext';
import { useConfig } from '../../../../../context/globalContext';
import type { CampaignForm } from '../../../../../types/campaignForm';
import { isOngoing } from '../../../../../util/campaignStatus';
import { getCopy } from '../../../../../util/copy';
import { imageErrorMessages } from '../../../../../util/copyKeys';
import { BannerEditorContainer } from '../BannerEditorContainer';
import type { ImageChangeEvent } from '../ImageUpload';
import { ImageUpload } from '../ImageUpload';
import { Block } from '../block/Block';
import { isReceipt } from '../utils/campaignEditor';

export const ImageField = (): React.JSX.Element => {
  const {
    control,
    formState,
    setError,
    clearErrors,
    watch,
    getValues,
    setValue,
  } = useFormContext<CampaignForm>();
  const { kivra_campaigns_enable_banner_editor: enableEditor } = useConfig();
  const campaignStatus = useCampaignStatus();
  const isDisabled = isOngoing(campaignStatus);
  return (
    <Block.Wrapper
      label={getCopy('campaigns__upload_content')}
      tooltipText={getCopy(
        isReceipt(getValues().documentType)
          ? 'campaigns__image_tooltip_receipt'
          : 'campaigns__image_tooltip_tenant'
      )}
    >
      <Root>
        <Controller
          name="image"
          control={control}
          defaultValue={''}
          rules={{
            required:
              watch('submitActionType') === 'publish' &&
              getCopy('campaigns__validation_error__image'),
          }}
          render={imageProps => (
            <ImageUpload
              disabled={isDisabled}
              errorMessage={formState.errors.image?.message}
              onChange={({ value, error }: ImageChangeEvent) => {
                if (error) {
                  setError('image', {
                    type: error,
                    message: imageErrorMessages(error),
                  });
                } else {
                  clearErrors('image');
                  setValue('bannerConfig', null, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                  imageProps.field.onChange(value);
                }
              }}
              isReceipt={isReceipt(getValues().documentType)}
            />
          )}
        />
        {enableEditor && (
          <Controller
            name="bannerConfig"
            control={control}
            render={editorProps => (
              <>
                <Margin top={8} bottom={6}>
                  <Caption
                    color={
                      isDisabled ? '$on-surface-disabled' : '$text-secondary'
                    }
                  >
                    {getCopy('campaigns__or')}
                  </Caption>
                </Margin>
                <BannerEditorContainer
                  disabled={isDisabled}
                  options={editorProps.field.value || getDefaultBannerOptions()}
                  onChange={(image: string, options: BannerConfig) => {
                    setValue('image', image, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                    editorProps.field.onChange(options);
                  }}
                />
              </>
            )}
          />
        )}
      </Root>
    </Block.Wrapper>
  );
};

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: '$spacing-12',
});
