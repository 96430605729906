import { createSynchronousStorage } from '@kivra/sdk/storage';

type StoredTenant = {
  sender: string;
  organizationId: string;
};

const storage = createSynchronousStorage<StoredTenant>(
  'kv.sender',
  () => localStorage
);

export const senderStorage = {
  delete: storage.remove,
  get: storage.get,
  set: storage.set,
};
