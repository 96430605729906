import React from 'react';
import type { RouteProps } from 'react-router-dom';
import { Redirect, Route, useRouteMatch } from 'react-router-dom';
import { isAuthenticated } from '@kivra/sdk/identity';
import type { FixThisAnyLater } from '@kivra/sdk/types/util/any';
import { history } from './history';

interface Props extends RouteProps {
  component: FixThisAnyLater;
}

export const PrivateRoute = ({
  component: Component,
  ...rest
}: Props): React.JSX.Element => {
  useRouteMatch();
  if (!isAuthenticated()) {
    // If not authenticated user is sent to login for auto login. Then redirected to expected route by onLoginRoute.
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { onLoginRoute: history.location.pathname },
        }}
      />
    );
  }
  return <Route {...rest} render={props => <Component {...props} />} />;
};
