import { styled, StyleException } from '@kivra/react-components';

export const Card = styled.div<{
  calcPixelsString: (pixels: number) => string;
}>(({ calcPixelsString }) => ({
  padding: StyleException(calcPixelsString(24)),
  width: '100%',
  borderRadius: '$radius-medium',
  border: '1px solid $border-subtle',
  backgroundColor: '$background-secondary',
  boxSizing: 'border-box',
}));
