import { styled } from '@kivra/react-components';
import { CheckmarkIcon } from '@kivra/react-components/icons';
import React from 'react';

type ListItemProps = {
  isActive?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
};

export const ListItem = ({
  isActive = false,
  onClick,
  children,
}: ListItemProps): React.JSX.Element => {
  return (
    <Wrapper onClick={onClick} selected={isActive}>
      <IconWrapper>{isActive && <CheckmarkIcon size={16} />}</IconWrapper>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled('div', { forwardProp: prop => prop !== 'selected' })<{
  selected: boolean;
}>(({ selected }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  height: 54,
  paddingRight: '$spacing-8',
  backgroundColor: selected ? '$surface-neutral' : undefined,
  '&:hover': {
    backgroundColor: selected ? undefined : '$hover-background',
  },
  '&:last-child': {
    marginBottom: '$spacing-56',
  },
}));

const IconWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 12,
  height: 12,
  marginLeft: '$spacing-8',
  marginRight: '$spacing-16',
  color: '$active-highlight',
});
