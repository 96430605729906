import { Body, Dialog, ErrorCard } from '@kivra/react-components';
import React, { useState } from 'react';
import { useSender } from '../context/senderContext';
import { useCurrentOrganization } from '../context/sendersContext';
import { getCopy } from '../util/copy';
import { deactivateCampaign } from '../util/handleUnauthorizedErrorWrapper';

interface Props {
  campaignId: string;
  bodyText: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const UnpublishDialog = ({
  campaignId,
  bodyText,
  open,
  onClose,
  onSuccess,
}: Props): React.JSX.Element => {
  const { key: senderKey } = useSender();
  const { organizationId } = useCurrentOrganization();

  const [error, setError] = useState<Error | null>(null);

  const unpublishHandler = (): Promise<void> => {
    return deactivateCampaign(organizationId, senderKey, campaignId)
      .then(onSuccess)
      .catch((error: unknown) => setError(error as Error));
  };

  const errorElement = error && (
    <ErrorCard severity="high">
      <ErrorCard.Title>
        {getCopy('campaigns__unpublish_dialog_error_title')}
      </ErrorCard.Title>
      <ErrorCard.Text>
        {getCopy('campaigns__dialog_error_generic_body')}
      </ErrorCard.Text>
    </ErrorCard>
  );
  return (
    <Dialog.Confirmation
      actionText={getCopy('btn__unpublish')}
      cancelText={getCopy('btn__cancel_dialog')}
      onConfirm={unpublishHandler}
      onClose={onClose}
      open={open}
      title={getCopy('campaigns__unpublish_dialog_title')}
      ariaLabelCloseIcon={getCopy('btn__cancel_dialog')}
      onDismissFocusRef={undefined}
    >
      <Body size="medium" gutterBottom>
        {bodyText}
      </Body>
      {errorElement}
    </Dialog.Confirmation>
  );
};
