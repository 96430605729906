import { css, NavDrawer } from '@kivra/react-components';
import type { ComponentProps } from 'react';
import React from 'react';
import { dashboardNavDrawerWidth } from '../pages/dashboard/components/DashboardDrawer';

type Props = ComponentProps<typeof NavDrawer>;

export const CampaignsNavDrawer: React.FC<Props> = props => {
  return (
    <div
      className={css({
        minWidth: dashboardNavDrawerWidth,
        $medium: {
          minWidth: 0,
        },
      })}
    >
      <NavDrawer {...props}>{props.children}</NavDrawer>
    </div>
  );
};
