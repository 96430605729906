import React from 'react';
import { Redirect, Route, Switch, Router } from 'react-router-dom';
import { ServiceMessageProvider } from '@sender-portal-fe/util-shared/src/components/ServiceMessageProvider';
import { SmallScreenBoundary } from '../components/SmallScreenBoundary';
import { NoMatch } from '../pages/404/NoMatch';
import { HeimdallLogin } from '../pages/authentication/HeimdallLogin';
import { LoadingOverlayProvider } from '../context/LoadingOverlayProvider';
import { getCopy } from '../util/copy';
import { history } from './history';
import { PrivateRoute } from './PrivateRoute';
import { PrivateRoutes } from './PrivateRoutes';

export const Routes = (): React.JSX.Element => {
  return (
    <ServiceMessageProvider getCopy={getCopy}>
      <LoadingOverlayProvider>
        <SmallScreenBoundary>
          <Router history={history}>
            <Switch>
              <Redirect exact from="/" to="/login" />
              <PrivateRoute
                path={[
                  '/organization',
                  '/select-organization',
                  '/access-denied-sender',
                ]}
                component={PrivateRoutes}
              />
              <Route exact path="/login" component={HeimdallLogin} />
              <Route path="*">
                <NoMatch />
              </Route>
            </Switch>
          </Router>
        </SmallScreenBoundary>
      </LoadingOverlayProvider>
    </ServiceMessageProvider>
  );
};
