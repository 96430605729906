import { Button, MenuItem, MenuList, Popover } from '@kivra/react-components';
import { MenuIcon } from '@kivra/react-components/icons';
import React, { useRef, useState } from 'react';
import type { Action, CampaignAction } from './CampaignActions';

type Props = {
  menuActions: CampaignAction[];
  actions: Action[];
};

export const CampaignPopoverMenu = ({
  menuActions,
  actions,
}: Props): React.JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Button
        variant="link"
        onClick={() => setIsOpen(true)}
        ref={anchorRef}
        aria-expanded={isOpen}
        aria-haspopup
        aria-controls="popover"
        dataTestId="CampaignPopoverMenu"
      >
        <Button.Icon
          iconComponent={() => <MenuIcon size={24} />}
          color="$text-secondary"
        />
      </Button>
      <Popover
        isOpen={isOpen}
        anchorRef={anchorRef}
        onClose={() => setIsOpen(false)}
        drawerOnSmallScreenSize={false}
      >
        <MenuList>
          {menuActions.map(actionKey => {
            const action = actions.find(({ key }) => key === actionKey);
            return action ? (
              <MenuItem
                key={actionKey}
                onClick={() => {
                  setIsOpen(false);
                  action.onClick();
                }}
                icon={action.icon}
                title={action.text}
                size="small"
              />
            ) : null;
          })}
        </MenuList>
      </Popover>
    </>
  );
};
