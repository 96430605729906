import { TextField } from '@kivra/react-components';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { CampaignForm } from '../../../../../types/campaignForm';
import { Block } from '../block/Block';
import { getCopy } from '../../../../../util/copy';

export const TitleField = (): React.JSX.Element => {
  const { control, setValue } = useFormContext<CampaignForm>();
  return (
    <Block.Wrapper label={getCopy('campaigns__title')} optional={true}>
      <Controller
        control={control}
        name="title"
        render={props => (
          <TextField
            dataTestId="CampaignTitleField"
            size="small"
            value={props.field.value}
            onTextChange={props.field.onChange}
            onBlur={e => {
              setValue('title', e.target.value.trimEnd());
              return props.field.onBlur;
            }}
          />
        )}
      />
    </Block.Wrapper>
  );
};
