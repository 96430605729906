import { senderRequest } from '@kivra/sdk/common';
import { userSession } from '@kivra/sdk/identity';

/**
 * Revokes the access token for the current session.
 */
export function revokeToken(): Promise<void> {
  const token = userSession.getToken();
  if (!token) {
    return Promise.resolve();
  }
  userSession.deleteSession();
  return senderRequest.post({
    path: '/v2/oauth2/token/revoke',
    payload: {
      token,
      token_type_hint: 'access_token',
    },
  });
}
