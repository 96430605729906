import {
  ErrorCard,
  Flex,
  Heading,
  Loader,
  Margin,
} from '@kivra/react-components';
import type { Campaign } from '@sender-portal-fe/util-shared/src/sdk/campaigns/types/campaign';
import type { ClientStatusType } from '@sender-portal-fe/util-shared/src/sdk/campaigns/types/clientStatus';
import React from 'react';
import { getCopy } from '../util/copy';
import { campaignStatusTexts } from '../util/copyKeys';
import type { State as ServiceState } from '../util/useServiceCaller';

interface Props {
  serviceState: ServiceState<Campaign[]>;
  type: ClientStatusType;
  actionBar?: React.JSX.Element;
  children?: React.ReactNode;
}

export const CampaignsBlock = ({
  serviceState,
  type,
  children,
  actionBar,
}: Props): React.JSX.Element => {
  const loadingElement = serviceState.status === 'loading' && (
    <Margin all={16}>
      <Loader />
    </Margin>
  );
  const errorElement = serviceState.status === 'error' && (
    <ErrorCard severity="high">
      <ErrorCard.Title>
        {getCopy('campaigns__get_campaigns_error_title')}
      </ErrorCard.Title>
      <ErrorCard.Text>
        {getCopy('campaigns__dialog_error_generic_body')}
      </ErrorCard.Text>
    </ErrorCard>
  );
  const successElement = serviceState.status === 'success' && children;
  const campaignCount =
    serviceState.status === 'success' && serviceState.response.length
      ? `(${serviceState.response.length})`
      : '';
  return (
    <>
      <Margin bottom={8} top={12}>
        <Flex justifyContent="space-between" alignItems="flex-start">
          <Heading size="large">
            {`${getCopy(campaignStatusTexts[type])} ${campaignCount}`}
          </Heading>
          {actionBar && Boolean(campaignCount) && actionBar}
        </Flex>
      </Margin>

      {loadingElement}
      {errorElement}
      {successElement}
    </>
  );
};
