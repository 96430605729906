import { Body, Heading, Switch, css, styled } from '@kivra/react-components';
import React, { useState } from 'react';
import {
  useCampaign,
  useCampaignType,
} from '../../../../../context/campaignContext';
import { useConfig } from '../../../../../context/globalContext';
import { getCopy } from '../../../../../util/copy';
import { useConfigUrls } from '../../../../../util/useConfigUrls';
import { Block } from '../block/Block';
import { TagSwitchDialog } from './TagSwitchDialog';

export const TagSwitch = (): React.JSX.Element | null => {
  const { isTagCampaign } = useCampaignType();
  const { campaign } = useCampaign(false);
  const { kivra_campaigns_enable_campaign_by_tag: isCampaignByTagEnabled } =
    useConfig();
  const { readMoreUrl } = useConfigUrls();

  const [isDisplayingTypeDialog, setIsDisplayingTypeDialog] = useState(false);

  // If a campaign has been saved it cannot be turned into another type of campaign
  if (!isCampaignByTagEnabled || Boolean(campaign)) {
    return null;
  }

  return (
    <>
      <TagSwitchDialog
        onClose={() => setIsDisplayingTypeDialog(false)}
        open={isDisplayingTypeDialog}
        isTagCampaign={isTagCampaign}
      />
      <Segment data-component-type="TagSwitch">
        <Block.Tooltip
          tooltipText={getCopy('campaigns__tag_segmentation_header_tooltip')}
        />
        <Heading size="small" gutterBottom>
          {getCopy('campaigns__tag_segmentation_header')}
        </Heading>
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            gap: '$spacing-8',
          })}
        >
          <Body size="small">
            {getCopy('campaigns__tag_segmentation_switch_label')}
          </Body>
          <InputWrapper>
            <Switch
              ariaLabelText={getCopy(
                'campaigns__tag_segmentation_switch_label'
              )}
              checked={isTagCampaign}
              dataTestId="ByTagSwitch"
              onChange={() => {
                setIsDisplayingTypeDialog(true);
              }}
            />
          </InputWrapper>
        </div>
        {!isTagCampaign && (
          <Block.InfoCard backgroundColor="$background-primary">
            {getCopy('campaigns__want_to_know_more_about_tag_segmentation')}
            <Block.InfoCardLink
              url={readMoreUrl}
              text={getCopy('campaigns__tag_read_more_link')}
            />
          </Block.InfoCard>
        )}
      </Segment>
    </>
  );
};

const InputWrapper = styled.div({
  marginRight: '-$spacing-8',
});

const Segment = styled.div({
  margin: ['$spacing-16', '$spacing-16'],
  padding: ['$spacing-8', '$spacing-16', '$spacing-16'],
  borderRadius: '$radius-medium',
  backgroundColor: '$background-secondary',
  position: 'relative',
});
