import { Margin } from '@kivra/react-components';
import { KivraIcon } from '@kivra/react-components/icons';
import React from 'react';
import {
  useCurrentOrganization,
  useSenders,
} from '../../context/sendersContext';
import { getCopy } from '../../util/copy';
import { AuthenticatedEntityLayout } from './AuthenticatedEntityLayout';
import { UserImage } from './UserImage';

export const OverviewLogoAndName = (): React.JSX.Element => {
  const { isAdminOrganization, organizationName } = useCurrentOrganization();
  const senders = useSenders();
  return (
    <AuthenticatedEntityLayout
      subtitle={
        isAdminOrganization ? 'Kivra Backoffice Admin' : organizationName
      }
      title={getCopy('campaigns__overview_all_tenants')}
      icon={
        isAdminOrganization ? (
          <Margin right={15} left={5} top={5}>
            <KivraIcon size={24} colorToken="$green-300" />
          </Margin>
        ) : (
          <UserImage src={senders[0]?.iconUrl} alt="user avatar" />
        )
      }
    />
  );
};
