import type { Order, TableColumnData } from '@kivra/react-components';
import { Margin, Table } from '@kivra/react-components';
import type { Campaign } from '@sender-portal-fe/util-shared/src/sdk/campaigns/types/campaign';
import type { ClientStatusType } from '@sender-portal-fe/util-shared/src/sdk/campaigns/types/clientStatus';
import React, { useState } from 'react';
import { LoadMore } from '../../../../components/LoadMore';
import { useSender } from '../../../../context/senderContext';
import type {
  CampaignTableColumns,
  CampaignTableProps,
} from '../../../../types/table';
import { getCopy } from '../../../../util/copy';
import {
  getHeaderLabel as getCampaignTableHeaderLabel,
  getDocumentTypeText,
  tablePositionCopy,
} from '../../../../util/copyKeys';
import { getTimePeriodText, getUpdatedAtString } from '../../../../util/dates';
import { getSorting, stableSort } from '../../../../util/stableSort';
import { tablePageSize } from '../../../../util/tablePageSize';
import { CampaignRow } from './CampaignRows';
import {
  defaultSortByKey,
  getSegmentationText,
  getVisibleColumns,
} from './utils/campaignTableUtils';

interface Props {
  campaigns: Campaign[];
  campaignType: ClientStatusType;
}

export type SortableColumns = Exclude<CampaignTableColumns, 'info'>;

export const CampaignTableContainer = ({
  campaigns,
  campaignType,
}: Props): React.JSX.Element => {
  const { type: senderType } = useSender();
  const visibleColumns = getVisibleColumns(campaignType, senderType);
  const [rowsToRender, setRowsToRender] = useState(tablePageSize);
  const isSortableColumn = (
    columnId: CampaignTableColumns
  ): columnId is SortableColumns => {
    const invalidValues: Array<Exclude<CampaignTableColumns, SortableColumns>> = [
      'info',
    ];
    return !(invalidValues as string[]).includes(columnId);
  };
  const tableColumns: Array<TableColumnData<SortableColumns>> = [
    ...visibleColumns.map(column => {
      const title = getCampaignTableHeaderLabel(column);
      if (isSortableColumn(column)) {
        return {
          id: column,
          sortable: isSortableColumn(column),
          title,
        };
      }
      return {
        id: column,
        title,
        sortable: false as const,
      };
    }),
    { id: 'contextMenu' },
  ];

  const rows: CampaignTableProps[] = campaigns.map(
    ({
      id,
      image,
      title,
      activeFrom,
      activeTo,
      documentType,
      position,
      updatedAt,
      segmentation,
      tag,
    }) => ({
      id,
      image,
      title: title || getCopy('campaigns__title_placeholder'),
      documentType: getDocumentTypeText(documentType),
      position: getCopy(tablePositionCopy[position]),
      publishedAt: updatedAt,
      updatedAt: updatedAt,
      updatedAtText: getUpdatedAtString(updatedAt, getCopy),
      // Time period is needed to sort on timePeriod
      tag: tag || '',
      timePeriod: !activeFrom || !activeTo ? '' : activeFrom + activeTo,
      timePeriodText: getTimePeriodText(activeFrom, activeTo),
      segmentation: getSegmentationText(segmentation),
    })
  );

  const [order, setOrder] = useState<Order>('descending');
  const [orderBy, setOrderBy] = useState<SortableColumns>(
    defaultSortByKey[campaignType]
  );

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: SortableColumns
  ): void => {
    const isDesc = orderBy === property && order === 'descending';
    setOrder(isDesc ? 'ascending' : 'descending');
    setOrderBy(property);
  };

  const sortedTableRows = stableSort(rows, getSorting(order, orderBy));

  const rowsElements = sortedTableRows.map((row, index) => (
    <CampaignRow
      campaignStatus={campaignType}
      key={index}
      tableRow={row}
      visibleColumns={visibleColumns}
    />
  ));

  return (
    <>
      <Table<SortableColumns>
        handleRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy}
        columns={tableColumns}
        rowsElements={rowsElements.slice(0, rowsToRender)}
      />
      <Margin top={40}>
        <LoadMore
          current={rowsToRender}
          total={rowsElements.length}
          onClick={() => setRowsToRender(rowsToRender + tablePageSize)}
        />
      </Margin>
    </>
  );
};
