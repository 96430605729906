import React, { useEffect } from 'react';
import { useServiceMessage } from '@sender-portal-fe/util-shared/src/components/ServiceMessageProvider';
import { useSender } from '../../../../../context/senderContext';
import { useCurrentOrganization } from '../../../../../context/sendersContext';
import { getCopy } from '../../../../../util/copy';
import { getUntypedSenderKey } from '../../../../../util/sender';
import { useDocumentTypesQuery } from '../../../../../__generated__/graphql';
import { SelectField } from './SelectField';

export const DocumentTypeField = (): React.JSX.Element | null => {
  const { addServiceMessage } = useServiceMessage();
  const { key } = useSender();
  const { organizationId } = useCurrentOrganization();
  const { data, loading, error } = useDocumentTypesQuery({
    variables: {
      senderKey: getUntypedSenderKey(key),
      organizationKey: organizationId,
    },
  });

  useEffect(() => {
    if (
      error ||
      (!loading && data?.sender.campaignsDomain.documentTypes.length === 0)
    ) {
      addServiceMessage({
        title: getCopy('error_generic__title'),
        body: getCopy('campaigns__dialog_error_generic_body'),
        variant: 'error',
      });
    }
  }, [data, loading, error]);

  if (error || loading) {
    return null;
  }

  const documentTypeOptions: Array<{
    label: string;
    value: string;
  }> =
    data?.sender.campaignsDomain.documentTypes.map(({ key, label }) => ({
      label,
      value: key,
    })) || [];

  return <SelectField name="documentType" options={documentTypeOptions} />;
};
