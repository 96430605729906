import { getPageLanguage } from '@kivra/sdk/copy-consumer';
import { useConfig } from '../context/globalContext';

export const useConfigUrls = (): {
  getInTouchUrl: string;
  readMoreUrl: string;
} => {
  const isSwedish = getPageLanguage() === 'sv';

  const {
    kivra_campaigns_get_in_touch_url_sv,
    kivra_campaigns_get_in_touch_url_en,
    kivra_campaigns_read_more_url_sv,
    kivra_campaigns_read_more_url_en,
  } = useConfig();

  return {
    getInTouchUrl: isSwedish
      ? kivra_campaigns_get_in_touch_url_sv
      : kivra_campaigns_get_in_touch_url_en,
    readMoreUrl: isSwedish
      ? kivra_campaigns_read_more_url_sv
      : kivra_campaigns_read_more_url_en,
  };
};
