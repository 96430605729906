import type { TargetGroupType } from '@sender-portal-fe/util-shared/src/sdk/campaigns/types/targetGroup';
import React from 'react';
import { getCopy } from '../../../../../util/copy';
import { SelectField } from './SelectField';

export const TargetGroupField = (): React.JSX.Element => {
  const targetOptions: Array<{ label: string; value: TargetGroupType }> = [
    { label: getCopy('campaigns__target_group__all'), value: 'all' },
    { label: getCopy('campaigns__target_group__user'), value: 'user' },
    { label: getCopy('campaigns__target_group__company'), value: 'company' },
  ];
  return <SelectField name="targetGroup" options={targetOptions} />;
};
