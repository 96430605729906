import React from 'react';
import { useCalcPixelsString } from '../../../context/previewContext';
import { Shape } from '../utilComponents';
import { Card } from './Card';

export const ActionCard = (): React.JSX.Element => {
  const calcPixelsString = useCalcPixelsString();
  const metaFieldAmount = 2;
  const metaFields: React.JSX.Element[] = [];
  for (let i = 0; metaFieldAmount > i; i++) {
    metaFields.push(
      <div
        key={`ActionMeta${i}`}
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: calcPixelsString(12),
          paddingRight: 0,
          paddingBottom: calcPixelsString(12),
          paddingLeft: 0,
        }}
      >
        <Shape
          width={calcPixelsString(24)}
          height={calcPixelsString(24)}
          style={{ marginRight: calcPixelsString(12) }}
        />
        <Shape width={calcPixelsString(100)} height={calcPixelsString(14)} />
      </div>
    );
  }

  return (
    <Card calcPixelsString={calcPixelsString}>
      <Shape
        width={calcPixelsString(125)}
        height={calcPixelsString(20)}
        style={{ marginBottom: calcPixelsString(20) }}
      />
      {metaFields.map(field => field)}
    </Card>
  );
};
