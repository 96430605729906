import type { BorderRadiusToken, StyleObject } from '@kivra/react-components';
import { styled } from '@kivra/react-components';

export const LayoutBase = styled('div')({
  overflow: 'auto',
  width: '100%',
  zIndex: 100,
  backgroundColor: '$background-primary',
});

export const Shape = styled.div<{
  height: string;
  width?: string;
  backgroundColor?: StyleObject['backgroundColor'];
  borderRadius?: BorderRadiusToken;
}>(
  ({
    height,
    width = '100%',
    backgroundColor = '$surface-neutral',
    borderRadius = '$radius-circle',
  }) => ({
    backgroundColor: backgroundColor,
    borderRadius,
    width: width,
    height,
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
  })
);

export const DocumentShape = styled.div<{
  height: string;
  width: string;
  borderRadius?: BorderRadiusToken;
}>(({ height, width, borderRadius = '$radius-circle' }) => ({
  backgroundColor: '!!$black4%',
  borderRadius,
  width: width,
  height,
  transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1)',
}));
