import { Body, Dialog, ErrorCard } from '@kivra/react-components';
import { AlertFilledIcon } from '@kivra/react-components/icons';
import React, { useState } from 'react';
import { useSender } from '../context/senderContext';
import { useCurrentOrganization } from '../context/sendersContext';
import { getCopy } from '../util/copy';
import { deleteCampaign } from '../util/handleUnauthorizedErrorWrapper';

interface Props {
  campaignId: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const DeleteDialog = ({
  campaignId,
  open,
  onClose,
  onSuccess,
}: Props): React.JSX.Element => {
  const { key: senderKey } = useSender();
  const { organizationId } = useCurrentOrganization();
  const [error, setError] = useState<Error | null>(null);

  const deleteHandler = (): Promise<void> => {
    return deleteCampaign(organizationId, senderKey, campaignId)
      .then(() => onSuccess())
      .catch((error: unknown) => setError(error as Error));
  };
  const errorElement = error && (
    <ErrorCard severity="high">
      <ErrorCard.Title>
        {getCopy('campaigns__delete_dialog_error_title')}
      </ErrorCard.Title>
      <ErrorCard.Text>
        {getCopy('campaigns__dialog_error_generic_body')}
      </ErrorCard.Text>
    </ErrorCard>
  );
  return (
    <Dialog.Destructive
      actionText={getCopy('btn__delete')}
      cancelText={getCopy('btn__cancel_dialog')}
      onConfirm={deleteHandler}
      onClose={onClose}
      title={getCopy('campaigns__delete_dialog__title')}
      open={open}
      icon={<AlertFilledIcon size={24} colorToken="$error-text" />}
      ariaLabelCloseIcon={getCopy('btn__cancel_dialog')}
      onDismissFocusRef={undefined}
    >
      <Body size="medium" gutterBottom>
        {getCopy('campaigns__delete_dialog__body')}
      </Body>
      {errorElement}
    </Dialog.Destructive>
  );
};
