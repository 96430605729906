import { userSession } from '@kivra/sdk/identity';
import { decodeIdToken } from '@sender-portal-fe/util-shared/src/sdk/authentication/heimdall';
import type { PersonaIdToken } from '@sender-portal-fe/util-shared/src/sdk/authentication/types/heimdall';
import React, { useState } from 'react';
import { OrganizationsContext } from './organizationsContext';

const getInitialOrganizationIds = (): string[] => {
  try {
    return (
      decodeIdToken<PersonaIdToken>(userSession.getSession()?.userId || '')
        .organizations || []
    );
  } catch {
    return [];
  }
};

type Props = {
  children?: React.ReactNode;
};

export const OrganizationsProvider = ({
  children,
}: Props): React.JSX.Element => {
  const [organizationIds, setOrganizationIds] = useState<string[]>(
    getInitialOrganizationIds()
  );

  return (
    <OrganizationsContext.Provider
      value={{
        organizationIds,
        setOrganizationIds,
      }}
    >
      {children}
    </OrganizationsContext.Provider>
  );
};
