import type { Order, TableColumnData } from '@kivra/react-components';
import { Dialog, Flex, Margin, Table } from '@kivra/react-components';
import type { Campaign } from '@sender-portal-fe/util-shared/src/sdk/campaigns/types/campaign';
import React, { useState } from 'react';
import { LoadMore } from '../../../components/LoadMore';
import { useCurrentOrganization } from '../../../context/sendersContext';
import { getCopy } from '../../../util/copy';
import { senderContentTypeCopy } from '../../../util/copyKeys';
import { getTimePeriodText } from '../../../util/dates';
import { getSorting, stableSort } from '../../../util/stableSort';
import { tablePageSize } from '../../../util/tablePageSize';
import { CampaignSummary } from './CampaignSummary';
import type { RowData } from './OrganizationCampaignRow';
import { OrganizationCampaignRow } from './OrganizationCampaignRow';

interface Props {
  campaigns: Campaign[];
  filter: string;
}

type SortableColumns =
  | 'title'
  | 'period'
  | 'organization'
  | 'sender'
  | 'senderType';

const searchableColumns: SortableColumns[] = [
  'title',
  'organization',
  'sender',
];

export const OrganizationCampaignsTable = ({
  campaigns,
  filter,
}: Props): React.JSX.Element => {
  const [campaignToView, setCampaignToView] = useState<Campaign | null>(null);
  const { isAdminOrganization } = useCurrentOrganization();
  const hasMultipleSenderTypes = campaigns.some(
    c => c.sender.type !== campaigns[0]?.sender.type
  );
  const [rowsToRender, setRowsToRender] = useState(tablePageSize);

  const hiddenColumns: string[] = [];

  if (!isAdminOrganization) hiddenColumns.push('organization');
  if (!hasMultipleSenderTypes) hiddenColumns.push('senderType');

  const tableColumns: Array<TableColumnData<SortableColumns>> = [
    {
      id: 'title',
      title: getCopy('campaigns__campaign'),
      sortable: true,
    },
    {
      id: 'period',
      title: getCopy('campaigns__time_period'),
      sortable: true,
    },
    {
      id: 'organization',
      title: getCopy('campaigns__organization'),
      sortable: true,
    },
    {
      id: 'sender',
      title: getCopy('campaigns__sender', 1),
      sortable: true,
    },
    {
      id: 'senderType',
      title: getCopy('campaigns__sender_type'),
      sortable: true,
    },
  ];

  const [order, setOrder] = useState<Order>('descending');
  const [orderBy, setOrderBy] = useState<SortableColumns>('period');

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: SortableColumns
  ): void => {
    const isDesc = orderBy === property && order === 'descending';
    setOrder(isDesc ? 'ascending' : 'descending');
    setOrderBy(property);
  };

  const rows: RowData[] = campaigns
    .map(
      ({ activeFrom, activeTo, id, image, title, organization, sender }) => ({
        title,
        id,
        image,
        // Period is needed to sort on period
        period: !activeFrom || !activeTo ? '' : activeFrom + activeTo,
        periodText: getTimePeriodText(activeFrom, activeTo),
        organization: organization.name || '-',
        sender: sender.name || '-',
        senderType: getCopy(senderContentTypeCopy[sender.type]),
      })
    )
    .filter(row =>
      searchableColumns
        .filter(c => !hiddenColumns.includes(c))
        .some(key => row[key].toLowerCase().includes(filter))
    );

  const sortedTableRows = stableSort(rows, getSorting(order, orderBy));

  const rowsElements = sortedTableRows.map(rowData => (
    <OrganizationCampaignRow
      key={rowData.id}
      rowData={rowData}
      hiddenColumns={hiddenColumns}
      onClick={() =>
        setCampaignToView(campaigns.find(({ id }) => id === rowData.id) || null)
      }
    />
  ));

  return sortedTableRows.length > 0 ? (
    <>
      <Table<SortableColumns>
        handleRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy}
        columns={tableColumns.filter(({ id }) => !hiddenColumns.includes(id))}
        rowsElements={rowsElements.slice(0, rowsToRender)}
      />
      <Margin top={40}>
        <LoadMore
          total={rowsElements.length}
          current={rowsToRender}
          onClick={() => setRowsToRender(rowsToRender + tablePageSize)}
        />
      </Margin>
      {campaignToView !== null && (
        <Dialog.Passive
          title={getCopy('campaigns__overview')}
          open={true}
          onClose={() => setCampaignToView(null)}
          width="stretched"
          ariaLabelCloseIcon={getCopy('btn__cancel_dialog')}
          onDismissFocusRef={undefined}
        >
          <CampaignSummary campaign={campaignToView} />
        </Dialog.Passive>
      )}
    </>
  ) : (
    <Margin top={64}>
      <Flex justifyContent="center">
        {getCopy('campaigns__search_campaign_no_result', { filter })}
      </Flex>
    </Margin>
  );
};
