import type { ColorToken } from '@kivra/react-components';
import { Link, css, font, styled } from '@kivra/react-components';
import React from 'react';

export const BlockInfoCard = styled.div<{ backgroundColor: ColorToken }>(
  ({ backgroundColor }) => ({
    padding: ['$spacing-12', '$spacing-10'],
    borderRadius: '$radius-small',
    marginTop: '$spacing-12',
    backgroundColor,
  })
);

export const BlockInfoCardLink = ({
  url,
  text,
  color,
}: {
  url: string;
  text: string;
  color?: ColorToken;
}): React.JSX.Element => {
  return (
    <Link hideIcon to={url}>
      <div
        className={css({
          marginTop: '$spacing-8',
          textDecoration: 'underline',
          textUnderlinePosition: 'under',
          textDecorationThickness: '1px',
          color: color,
          ...font.BodyS,
          '&:hover': {
            textDecorationThickness: '2px',
          },
        })}
      >
        {text}
      </div>
    </Link>
  );
};
