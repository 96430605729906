import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  css,
  Flex,
  Heading,
  Margin,
  styled,
} from '@kivra/react-components';
import { toPng } from 'html-to-image';
import { captureException } from '@kivra/sdk/log';
import type { BannerConfig } from '@sender-portal-fe/util-shared/src/sdk/campaigns/types/bannerConfig';
import { useServiceMessage } from '@sender-portal-fe/util-shared/src/components/ServiceMessageProvider';
import { getCopy } from '../../util/copy';
import { BannerPreview } from './BannerPreview';
import { BannerControls } from './BannerControls';
import { FontProvider } from './FontProvider';

type Props = {
  onSave: (dataUri: string, options: BannerConfig) => void;
  onEdit: (options: BannerConfig) => void;
  options: BannerConfig;
};

export const BannerEditor = (props: Props): React.JSX.Element => {
  const [bannerOptions, setBannerOptions] = useState<BannerConfig>(
    props.options
  );
  const [progress, setProgress] = useState(false);
  const { addServiceMessage } = useServiceMessage();

  useEffect(() => {
    props.onEdit(bannerOptions);
  }, [bannerOptions]);
  const bannerElement = useRef<HTMLDivElement>(null);

  const saveImage = (): void => {
    if (bannerElement.current) {
      setProgress(true);
      void toPng(bannerElement.current, { pixelRatio: 1, cacheBust: true })
        .then(dataUrl => {
          props.onSave(dataUrl, bannerOptions);
        })
        .catch((error: unknown) => {
          captureException(error);
          addServiceMessage({
            variant: 'error',
            title: getCopy('error_generic__title'),
            body: getCopy('campaigns__dialog_error_generic_body'),
          });
        })
        .then(() => {
          setProgress(false);
        });
    }
  };

  return (
    <div data-test-id="BannerEditor">
      <Flex justifyContent="center">
        <Heading size="medium">
          {getCopy('campaigns__banner_editor_create')}
        </Heading>
      </Flex>
      <FontProvider>
        <Layout>
          <BannerControls
            bannerOptions={bannerOptions}
            setBannerOptions={options =>
              setBannerOptions({ ...bannerOptions, ...options })
            }
          />
          <BannerPreview ref={bannerElement} bannerOptions={bannerOptions} />
        </Layout>
      </FontProvider>
      <Flex
        justifyContent="center"
        className={css({
          borderTop: '1px solid $border-distinct',
          $medium: {
            border: 'none',
          },
        })}
      >
        <Margin top={24}>
          <Button onClick={saveImage} size="medium" progress={progress}>
            {getCopy('campaigns__banner_editor_save')}
          </Button>
        </Margin>
      </Flex>
    </div>
  );
};

const Layout = styled.div(() => ({
  display: 'flex',
  $medium: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
  },
  '& >:first-of-type': {
    paddingRight: '$spacing-20',
    marginRight: '$spacing-20',
    borderRight: `1px solid $border-distinct`,
    height: 'calc(100vh - 301px)',
    minHeight: '321px',
    overflowY: 'auto',
    position: 'relative',
    $medium: {
      marginTop: '$spacing-48',
      marginBottom: '$spacing-24',
      marginRight: 0,
      width: 640,
      border: 'none',
      paddingRight: 0,
    },
  },
}));
