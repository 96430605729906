import React from 'react';
import { Button, ErrorPage, Margin } from '@kivra/react-components';
import { getRoute, goTo } from '../../routes/history';
import { senderStorage } from '../../util/senderStorage';
import { getCopy } from '../../util/copy';

export const AccessDeniedSender = (): React.JSX.Element => {
  senderStorage.delete();
  return (
    <ErrorPage title={getCopy('campaigns__tenant_access_error_title')}>
      {getCopy('campaigns__tenant_access_error_body')}
      <Margin top={16}>
        <Button
          fullWidth={false}
          onClick={() => goTo(getRoute({ id: 'select-organization' }))}
        >
          {getCopy('campaigns__goto_startpage_button')}
        </Button>
      </Margin>
    </ErrorPage>
  );
};
