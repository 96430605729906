import type { SenderType } from '@kivra/sdk/types/sender';
import type { ClientStatusType } from '@sender-portal-fe/util-shared/src/sdk/campaigns/types/clientStatus';
import type { Segmentation } from '@sender-portal-fe/util-shared/src/sdk/campaigns/types/segmentation';
import type { CampaignListType } from '../../../../../types/campaignList';
import type { CampaignTableColumns } from '../../../../../types/table';
import { getCopy } from '../../../../../util/copy';
import { isReceiptSender } from '../../../../../util/senderType';
import type { SortableColumns } from '../CampaignTable';

const defaultColumns: CampaignTableColumns[] = [
  'title',
  'timePeriod',
  'info',
  'publishedAt',
];
const previousColumns: CampaignTableColumns[] = [
  'title',
  'timePeriod',
  'segmentation',
  'position',
];
const draftColumns: CampaignTableColumns[] = [
  'title',
  'documentType',
  'segmentation',
  'position',
  'updatedAt',
];

const visibleColumns: Record<ClientStatusType, CampaignTableColumns[]> = {
  ongoing: defaultColumns,
  upcoming: defaultColumns,
  previous: previousColumns,
  draft: draftColumns,
};

const filterReceiptColumns = (
  columns: CampaignTableColumns[]
): CampaignTableColumns[] =>
  columns.filter(
    column => !['position', 'info', 'segmentation'].includes(column)
  );

export const getVisibleColumns = (
  campaignStatus: ClientStatusType,
  senderType: SenderType
): CampaignTableColumns[] =>
  isReceiptSender(senderType)
    ? filterReceiptColumns(visibleColumns[campaignStatus])
    : visibleColumns[campaignStatus];

export const getSegmentationText = (
  segmentation: Segmentation | null
): string => {
  if (segmentation) {
    return segmentation.status === 'ongoing'
      ? `${getCopy('campaigns__processing_file')}...`
      : segmentation.filename;
  }
  return '-';
};

export const defaultSortByKey: Record<CampaignListType, SortableColumns> = {
  draft: 'updatedAt',
  previous: 'timePeriod',
  ongoing: 'timePeriod',
  upcoming: 'timePeriod',
};
