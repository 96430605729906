import { Button, Margin } from '@kivra/react-components';
import type { IconProps } from '@kivra/react-components/icons';
import {
  CopyIcon,
  EditIcon,
  TrashIcon,
  UndoIcon,
} from '@kivra/react-components/icons';
import type { ComponentProps } from 'react';
import React, { useState } from 'react';
import { useLoadingOverlay } from '../context/LoadingOverlayProvider';
import { useCreateSenderRoute, useSender } from '../context/senderContext';
import { useCurrentOrganization } from '../context/sendersContext';
import { goTo } from '../routes/history';
import { getCopy } from '../util/copy';
import { duplicateCampaign } from '../util/duplicateCampaign';
import { CampaignPopoverMenu } from './CampaignPopoverMenu';
import { DeleteDialog } from './DeleteDialog';
import { UnpublishDialog } from './UnpublishDialog';

export type CampaignAction =
  | 'unpublish-ongoing'
  | 'unpublish-upcoming'
  | 'delete'
  | 'duplicate'
  | 'edit';

export type Action = {
  icon: (props: IconProps) => React.JSX.Element;
  onClick: () => void;
  text: string;
  key: CampaignAction;
} & Pick<ComponentProps<typeof Button>, 'variant'>;

interface Props {
  campaignId: string;
  menuActions?: CampaignAction[];
  buttonActions?: CampaignAction[];
  onUnpublishSuccess?: () => void;
  onDeleteSuccess?: () => void;
}

export const CampaignActions = (props: Props): React.JSX.Element => {
  const createRoute = useCreateSenderRoute();
  const defaultOnSuccess = (): void =>
    goTo(createRoute({ id: 'ongoing-and-upcoming' }));

  const organizationId = useCurrentOrganization().organizationId;
  const sender = useSender();
  const { setIsLoading, setErrorMessage } = useLoadingOverlay();

  const {
    campaignId,
    menuActions,
    buttonActions,
    onUnpublishSuccess = defaultOnSuccess,
    onDeleteSuccess = defaultOnSuccess,
  } = props;
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [unpublishDialog, setUnpublishDialog] = useState('');
  const gotoEdit = (): void =>
    goTo(
      createRoute({
        id: 'campaign',
        campaignId,
        pageType: 'edit',
      })
    );

  const goToDuplicate = (): void => {
    setIsLoading(true);
    duplicateCampaign(organizationId, sender.key, campaignId)
      .then(({ id }) => {
        goTo(
          createRoute({
            id: 'campaign',
            campaignId: id,
            pageType: 'edit',
          })
        );
      })
      .catch(() => {
        setErrorMessage();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const actions: Action[] = [
    {
      key: 'edit',
      text: getCopy('campaigns__edit_campaign'),
      icon: EditIcon,
      onClick: gotoEdit,
    },
    {
      key: 'duplicate',
      text: getCopy('campaigns__duplicate'),
      icon: CopyIcon,
      onClick: goToDuplicate,
    },
    {
      key: 'unpublish-ongoing',
      text: getCopy('btn__unpublish'),
      icon: UndoIcon,
      onClick: () =>
        setUnpublishDialog(
          getCopy('campaigns__unpublish_dialog_body__ongoing')
        ),
    },
    {
      key: 'unpublish-upcoming',
      text: getCopy('btn__unpublish'),
      icon: UndoIcon,
      onClick: () =>
        setUnpublishDialog(
          getCopy('campaigns__unpublish_dialog_body__upcoming')
        ),
    },
    {
      key: 'delete',
      text: getCopy('btn__delete'),
      icon: TrashIcon,
      onClick: () => setOpenDeleteDialog(true),
      variant: 'dismissive',
    },
  ];

  return (
    <>
      {buttonActions && (
        <>
          {buttonActions.map(actionKey => {
            const action = actions.find(({ key }) => key === actionKey);
            return action ? (
              <Margin right={8} key={actionKey}>
                <Button
                  onClick={action.onClick}
                  variant={action.variant || 'secondary'}
                  size="small"
                >
                  <Button.Icon iconComponent={action.icon} />
                  {action.text}
                </Button>
              </Margin>
            ) : null;
          })}
        </>
      )}
      {menuActions && (
        <CampaignPopoverMenu actions={actions} menuActions={menuActions} />
      )}

      <DeleteDialog
        campaignId={campaignId}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onSuccess={() => {
          setOpenDeleteDialog(false);
          onDeleteSuccess();
        }}
      />
      <UnpublishDialog
        campaignId={campaignId}
        bodyText={unpublishDialog}
        open={Boolean(unpublishDialog)}
        onClose={() => setUnpublishDialog('')}
        onSuccess={() => {
          setUnpublishDialog('');
          onUnpublishSuccess();
        }}
      />
    </>
  );
};
