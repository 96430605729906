import type { PositionType } from '@sender-portal-fe/util-shared/src/sdk/campaigns/types/position';
import React from 'react';
import { getCopy } from '../../../../../util/copy';
import { SelectField } from './SelectField';

export const PositionField = (): React.JSX.Element => {
  const positionOptions: Array<{ label: string; value: PositionType }> = [
    { label: getCopy('campaigns__position__top'), value: 'top' },
    { label: getCopy('campaigns__position__bottom'), value: 'bottom' },
  ];
  return <SelectField name="position" options={positionOptions} />;
};
