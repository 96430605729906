import { Body, Dialog, Link } from '@kivra/react-components';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useCreateSenderRoute } from '../../../../../context/senderContext';
import { getCopy } from '../../../../../util/copy';
import { useConfigUrls } from '../../../../../util/useConfigUrls';
import type { CampaignType } from '../../../../../util/campaignType';

interface Props {
  open: boolean;
  onClose: () => void;
  isTagCampaign: boolean;
}

export const TagSwitchDialog = ({
  isTagCampaign,
  open,
  onClose,
}: Props): React.JSX.Element => {
  const { getInTouchUrl } = useConfigUrls();
  const history = useHistory();
  const createRoute = useCreateSenderRoute();
  const gotoCreateCampaign = (campaignType: CampaignType): void => {
    history.replace(createRoute({ id: 'new-campaign', campaignType }));
  };

  const dialogBody = isTagCampaign ? (
    <Body size="medium">
      {getCopy('sender_portal__unsaved_changes_question')}
    </Body>
  ) : (
    <Body size="medium">
      {`${getCopy('campaigns__by_tag_modal_body')} `}
      <Link hideIcon to={getInTouchUrl}>
        {getCopy('campaigns__by_tag_modal_body_link')}
      </Link>
    </Body>
  );

  return (
    <Dialog.Confirmation
      ariaLabelCloseIcon={getCopy('btn__cancel_dialog')}
      onClose={onClose}
      onConfirm={() => {
        if (isTagCampaign) {
          gotoCreateCampaign('postal');
        } else {
          gotoCreateCampaign('by-tag');
        }
        onClose();
      }}
      onDismissFocusRef={undefined}
      actionText={
        isTagCampaign
          ? getCopy('sender_portal__dont_save')
          : getCopy('campaigns__by_tag_modal_button_continue_with_tag')
      }
      cancelText={getCopy('btn__cancel_dialog')}
      open={open}
      title={
        isTagCampaign
          ? getCopy('campaigns__by_tag_modal_heading_leave')
          : getCopy('campaigns__by_tag_modal_heading')
      }
    >
      {dialogBody}
    </Dialog.Confirmation>
  );
};
