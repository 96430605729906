import { Margin } from '@kivra/react-components';
import React, { useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import type { CampaignListType } from '../../types/campaignList';
import { DashboardTopBar } from '../../components/DashboardTopBar';
import { Main } from '../../components/campaignLayout/Main';
import { OrganizationCampaigns } from './components/OrganizationCampaigns';
import { OverviewCampaignsDrawer } from './components/OverviewCampaignsDrawer';

interface MatchParams {
  dashboardType: CampaignListType;
}

export const OrganizationOverview = ({
  match,
}: RouteComponentProps<MatchParams>): React.JSX.Element => {
  const selectedDashboard = match.params.dashboardType;

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = (): void => {
    setDrawerOpen(!drawerOpen);
  };

  const ongoingElement = selectedDashboard === 'ongoing' && (
    <OrganizationCampaigns types={['ongoing']} />
  );
  const upcomingElement = selectedDashboard === 'upcoming' && (
    <OrganizationCampaigns types={['upcoming']} />
  );
  const draftElement = selectedDashboard === 'draft' && (
    <OrganizationCampaigns types={['draft']} />
  );
  const previousElement = selectedDashboard === 'previous' && (
    <OrganizationCampaigns types={['previous']} />
  );

  return (
    <>
      <DashboardTopBar
        drawerOpen={drawerOpen}
        handleDrawerToggle={handleDrawerToggle}
        isOrganizationOverview={true}
      />
      <Main>
        <OverviewCampaignsDrawer
          open={drawerOpen}
          onClose={handleDrawerToggle}
          selectedMenuType={selectedDashboard}
        />
        <div style={{ flexGrow: 1 }}>
          <Margin left={24} right={24}>
            {ongoingElement}
            {upcomingElement}
            {draftElement}
            {previousElement}
          </Margin>
        </div>
      </Main>
    </>
  );
};
