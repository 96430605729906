import React, { useEffect } from 'react';
import { useIsSmallScreenSize } from '@kivra/react-components';
import { useServiceMessage } from '@sender-portal-fe/util-shared/src/components/ServiceMessageProvider';
import { getCopy } from '../util/copy';

type Props = {
  children?: React.ReactNode;
};

export const SmallScreenBoundary = ({
  children,
}: Props): React.JSX.Element | null => {
  const isSmallScreen = useIsSmallScreenSize();
  const { addServiceMessage } = useServiceMessage();
  useEffect(() => {
    if (isSmallScreen && process.env.NODE_ENV !== 'development') {
      return addServiceMessage({
        variant: 'info',
        title: getCopy('campaigns__small_screen_warning_title'),
        body: getCopy('campaigns__small_screen_warning_body'),
      });
    }
  }, [isSmallScreen]);

  return <>{children}</>;
};
