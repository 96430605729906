import React from 'react';
import { Body, styled } from '@kivra/react-components';
import { campaignStatusTexts } from '../util/copyKeys';
import type { CampaignListType } from '../types/campaignList';
import { getCopy } from '../util/copy';

const Wrapper = styled('div')({
  backgroundColor: '$background-secondary',
  borderRadius: '$radius-medium',
  padding: '$spacing-16',
  boxShadow: '$shadow-raised',
});

type Props = {
  type: CampaignListType;
};
export const NoCampaigns = ({ type }: Props): React.JSX.Element => {
  return (
    <Wrapper>
      <Body size={'medium'}>
        {getCopy('campaigns__no_campaigns', {
          status: getCopy(campaignStatusTexts[type]).toLocaleLowerCase(),
        })}
      </Body>
    </Wrapper>
  );
};
