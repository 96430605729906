import { format, isBefore, isSameDay, isToday, isYesterday } from 'date-fns';
import type { CampaignForm } from '../types/campaignForm';
import type { CopyKeysWithoutArgs } from '../types/copyKeys';

const dayMonthYearFormat = 'd MMM yyy';
const dayMonthTimeFormat = 'd MMM HH:mm';
const hoursMinutes = 'HH:mm';
const yearMonthDayFormat = 'yyyy-MM-dd';

export const getDayMonthYearText = (dateString: string): string => {
  const str = format(
    new Date(dateString),
    dayMonthYearFormat
  ).toLocaleLowerCase();
  return str;
};

export const getDayMonthTimeDate = (dateString: string): string => {
  return format(new Date(dateString), dayMonthTimeFormat).toLocaleLowerCase();
};

export const getTimePeriodText = (
  from: string | null,
  to: string | null
): string => {
  if (!from || !to) {
    return '';
  }
  return `${getDayMonthYearText(from)} - ${getDayMonthYearText(to)}`;
};

export const getUpdatedAtString = (
  updatedAtString: string,
  getCopy: (key: CopyKeysWithoutArgs) => string
): string => {
  const updatedAt = new Date(updatedAtString);
  if (isToday(updatedAt)) {
    return `${getCopy('campaigns__updated_today')} ${format(
      updatedAt,
      hoursMinutes
    )}`;
  } else if (isYesterday(updatedAt)) {
    return `${getCopy('campaigns__updated_yesterday')} ${format(
      updatedAt,
      hoursMinutes
    )}`;
  } else {
    return format(updatedAt, 'd MMMM yyyy').toLowerCase();
  }
};

export const isActiveToBeforeActiveFrom = ({
  activeFrom,
  activeTo,
}: CampaignForm['dates']): boolean =>
  Boolean(activeFrom && activeTo && isBefore(activeTo, activeFrom));

export const isAttachToBeforeAttachFrom = ({
  attachTo,
  attachFrom,
}: CampaignForm['dates']): boolean =>
  Boolean(attachTo && attachFrom && isBefore(attachTo, attachFrom));

export const isActiveFromBeforeToday = ({
  activeFrom,
}: CampaignForm['dates']): boolean =>
  Boolean(
    activeFrom && isBefore(activeFrom, new Date()) && !isToday(activeFrom)
  );

export const isActiveToBeforeAttachTo = ({
  attachTo,
  activeTo,
}: CampaignForm['dates']): boolean =>
  Boolean(
    attachTo &&
      activeTo &&
      !isSameDay(activeTo, attachTo) &&
      isBefore(activeTo, attachTo)
  );

export const isActiveFromBeforeAttachFrom = ({
  attachFrom,
  activeFrom,
}: CampaignForm['dates']): boolean =>
  Boolean(attachFrom && activeFrom && isBefore(activeFrom, attachFrom));

export const hasDateChanged = (
  oldDate: Date | null,
  newDate: Date | null
): boolean => {
  if ((!oldDate && newDate) || (oldDate && !newDate)) {
    return true;
  } else if (!oldDate && !newDate) {
    return false;
  } else {
    return Boolean(oldDate && newDate && !isSameDay(oldDate, newDate));
  }
};

export const getDateString = (date: Date): string =>
  format(date, yearMonthDayFormat);

export const isInThePast = (date: Date | string): boolean => {
  if (typeof date === 'string') {
    return new Date(date) < new Date();
  }
  return date < new Date();
};
