import React from 'react';
import { css, StyleException } from '@kivra/react-components';

type Props = {
  onClick: () => void;
  selected: boolean;
  deSelectable?: boolean;
  children?: React.ReactNode;
};

export const Selectable = ({
  onClick,
  selected,
  children,
  deSelectable = true,
}: Props): React.JSX.Element => {
  return (
    <div
      onClick={onClick}
      data-test-id="Selectable"
      className={css({
        backgroundColor: selected ? '$hover-background' : 'transparent',
        borderRadius: '$radius-small',
        cursor: selected && !deSelectable ? undefined : 'pointer',
        height: 28,
        margin: '$spacing-2',
        outline: 'none',
        padding: StyleException(6),
        width: 28,
        '.keyboardFocus &:focus': {
          outline: 'auto',
          outlineColor: '$focus-border',
        },
      })}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          onClick();
        }
      }}
      role="button"
      tabIndex={0}
    >
      {children}
    </div>
  );
};
