import { Heading } from '@kivra/react-components';
import React from 'react';
import { useStaticOrigin } from '../../context/globalContext';
import {
  useCalcPixelsString,
  usePreviewCampaign,
  usePreviewType,
} from '../../context/previewContext';
import { getCopy } from '../../util/copy';

type Props = { fallbackImagePath?: string };

export const CampaignImage = ({
  fallbackImagePath = '/assets/illustrations/campaigns/preview-fallback.svg',
}: Props): React.JSX.Element => {
  const { image } = usePreviewCampaign();
  const previewType = usePreviewType();
  const calcPixelsString = useCalcPixelsString();
  const staticOrigin = useStaticOrigin();

  return (
    <div
      style={{
        display: 'flex',
        position: 'relative',
        width: '100%',
      }}
    >
      <img
        style={{
          width: '100%',
          borderRadius: previewType === 'phone' ? 0 : calcPixelsString(8),
        }}
        src={image || `${staticOrigin}${fallbackImagePath}`}
        alt="campaign image"
      />
      {!image && (
        <Heading size="xsmall" align="center">
          <span
            style={{
              fontSize: calcPixelsString(14),
              maxWidth: calcPixelsString(200),
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translateX(-50%) translateY(-50%)',
            }}
          >
            {getCopy('campaigns__preview_content_info')}
          </span>
        </Heading>
      )}
    </div>
  );
};
