export const phoneHeight = 844;
export const phoneWidth = 413;
export const phoneWidthPadding = 19;
export const phoneHeightPadding = 56;
export const phoneInnerHeight =
  phoneHeight - phoneHeightPadding - phoneHeightPadding;
export const phoneInnerWidth =
  phoneWidth - phoneWidthPadding - phoneWidthPadding;

export const desktopHeight = 803;
export const desktopWidth = 1060;
export const desktopWidthPadding = 17;
export const desktopPaddingTop = 61;
export const desktopPaddingBottom = 36;
export const desktopInnerHeight =
  desktopHeight - desktopPaddingTop - desktopPaddingBottom;
export const desktopInnerWidth =
  desktopWidth - desktopWidthPadding - desktopWidthPadding;

export const DOCUMENT_WRAPPER_WIDTH_DESKTOP = 628;
export const DOCUMENT_WIDTH_DESKTOP = 628 - 8;
