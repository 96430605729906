import { StyleException, styled } from '@kivra/react-components';
import React from 'react';
import {
  useCalcPixelsString,
  usePreviewCampaign,
} from '../../../context/previewContext';
import { useSender } from '../../../context/senderContext';
import { Shape } from '../utilComponents';
import { Card } from './Card';

export const LetterCard = (): React.JSX.Element => {
  const { documentType } = usePreviewCampaign();
  const calcPixelsString = useCalcPixelsString();
  const { iconUrl } = useSender();
  const isInvoice = documentType === 'invoice';
  const metaFieldAmount = isInvoice ? 3 : 1;
  const metaFields: React.JSX.Element[] = [];
  for (let i = 0; metaFieldAmount > i; i++) {
    metaFields.push(
      <LetterBar
        key={`LetterBar${i}`}
        style={{ padding: calcPixelsString(12) }}
      >
        <Shape width={calcPixelsString(100)} height={calcPixelsString(14)} />
        <Shape width={calcPixelsString(100)} height={calcPixelsString(14)} />
      </LetterBar>
    );
  }

  const headingShape = (
    <Shape
      backgroundColor="$border-subtle"
      width={calcPixelsString(125)}
      height={calcPixelsString(20)}
    />
  );

  const logoElement = (
    <Logo
      style={{
        maxWidth: calcPixelsString(64),
        height: calcPixelsString(64),
        marginRight: calcPixelsString(16),
      }}
    >
      <img
        style={{
          width: calcPixelsString(46),
          height: calcPixelsString(46),
        }}
        src={iconUrl}
        alt=""
      />
    </Logo>
  );

  const logoMetaShapes = (
    <div>
      <Shape
        width={calcPixelsString(75)}
        height={calcPixelsString(14)}
        style={{ marginBottom: calcPixelsString(4) }}
      />
      <Shape width={calcPixelsString(100)} height={calcPixelsString(12)} />
    </div>
  );

  return (
    <Card calcPixelsString={calcPixelsString}>
      {headingShape}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: calcPixelsString(16),
          marginTop: calcPixelsString(16),
        }}
      >
        {logoElement}
        {logoMetaShapes}
      </div>
      {metaFields.map(field => field)}
      {isInvoice && (
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            marginTop: calcPixelsString(24),
          }}
        >
          <Shape
            backgroundColor="$button-primary"
            width={calcPixelsString(200)}
            height={calcPixelsString(40)}
          />
        </div>
      )}
    </Card>
  );
};

const LetterBar = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid $border-subtle',
  borderTop: '1px solid $border-subtle',
});

const Logo = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  borderRadius: StyleException('50%'),
  border: '1px solid $border-subtle',
});
