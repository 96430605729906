// This file is copied from kivra_fe_sdk repo
import {
  convertPropertyNamesToCamelCase,
  senderRequest,
} from '@kivra/sdk/common';
import { userSession } from '@kivra/sdk/identity';
import type { LegitimateAny } from '@kivra/sdk/types/util/any';
import type { Organization } from './types/organization';

export const getOrganizations = async (): Promise<Organization[]> => {
  const response: LegitimateAny = await senderRequest.get({
    path: '/v1/organization',
    accessToken: userSession.getToken(),
  });
  return response.map((org: LegitimateAny) =>
    convertPropertyNamesToCamelCase<Organization>(org)
  );
};

export const getOrganization = async (
  organizationId: string
): Promise<Organization> => {
  const response: LegitimateAny = await senderRequest.get({
    path: `/v1/organization/${organizationId}`,
    accessToken: userSession.getToken(),
  });

  return convertPropertyNamesToCamelCase<Organization>(response);
};

export const createOrganization = async (
  name: string,
  senderKeys: string[]
): Promise<{ organizationId: string }> => {
  const { kivraObjKey } = await senderRequest.post<{ kivraObjKey: string }>({
    path: '/v1/organization',
    accessToken: userSession.getToken(),
    payload: {
      name,
      senders: senderKeys,
    },
  });

  return { organizationId: kivraObjKey };
};

export const deleteOrganization = async (
  organizationId: string
): Promise<void> => {
  return await senderRequest.delete<void>({
    path: `/v1/organization/${organizationId}`,
    accessToken: userSession.getToken(),
  });
};

export const removeSenderFromOrganization = async (
  senderKey: string,
  organizationId: string
): Promise<void> => {
  return await senderRequest.delete<void>({
    path: `/v1/organization/${organizationId}/sender/${senderKey}`,
    accessToken: userSession.getToken(),
  });
};

export const addSenderToOrganization = async (
  senderKey: string,
  organizationId: string
): Promise<void> => {
  return await senderRequest.post<void>({
    path: `/v1/organization/${organizationId}/sender/${senderKey}`,
    accessToken: userSession.getToken(),
    payload: {},
  });
};
