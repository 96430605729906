import { ErrorPage, FullPageLoader, useOnMount } from '@kivra/react-components';
import type { Session } from '@kivra/sdk/identity';
import { userSession } from '@kivra/sdk/identity';
import { captureException } from '@kivra/sdk/log';
import {
  createHeimdallAuthentication,
  decodeIdToken,
} from '@sender-portal-fe/util-shared/src/sdk/authentication/heimdall';
import type {
  HeimdallToken,
  PersonaIdToken,
} from '@sender-portal-fe/util-shared/src/sdk/authentication/types/heimdall';
import React, { useState } from 'react';
import { useConfig, useHeimdallParameters } from '../../context/globalContext';
import { history, setOnLoginRoute } from '../../routes/history';
import { onLoginSuccess } from '../../util/authentication';
import { getCopy } from '../../util/copy';
import type { LoginError } from './HeimdallError';
import { HeimdallError } from './HeimdallError';

const getOrganizationIdsFromIdToken = (idToken: string): string[] => {
  const decodedToken = decodeIdToken<PersonaIdToken>(idToken);
  return decodedToken.organizations || [];
};

const login = (token: HeimdallToken): void => {
  const session: Session = {
    accessToken: token.accessToken,
    expiryTime: Math.floor(
      (new Date().getTime() + token.expiresIn * 1000) / 1000
    ),
    userId: token.idToken,
    userType: 'non_user',
  };
  userSession.setSession(session);
};

export const HeimdallLogin: React.FC = () => {
  const {
    authorizationCode,
    redirectState,
    error: errorParameter,
  } = useHeimdallParameters();
  const [error, setError] = useState<LoginError | undefined>(errorParameter);
  const [isDenied, setIsDenied] = useState(false);

  const config = useConfig();

  const heimdallAuthentication = createHeimdallAuthentication<{
    route: string;
  }>({
    clientId: config.kivra_campaigns_heimdall_client_id,
    heimdallUrl: config.sender_api_origin,
    redirectUrl: config.kivra_campaigns_uri_origin,
  });
  const onLoginRoute = history.location.state?.onLoginRoute || '/';

  useOnMount(async () => {
    if (authorizationCode && redirectState) {
      const tokenResponse = await heimdallAuthentication.getToken(
        authorizationCode,
        redirectState
      );

      if (tokenResponse.error || !tokenResponse.applicationState) {
        setError('unknown');
      } else {
        const { token, applicationState } = tokenResponse;
        const organizations = getOrganizationIdsFromIdToken(token.idToken);
        if (organizations.length === 0) {
          setIsDenied(true);
        } else {
          login(token);
          setOnLoginRoute(applicationState.route);
          void onLoginSuccess();
        }
      }
    } else if (!error) {
      heimdallAuthentication
        .initiateLogin({
          route: onLoginRoute,
        })
        .catch((error: unknown) => {
          captureException(error);
          setError('unknown');
        });
    }
  });

  if (error) {
    return <HeimdallError error={error} />;
  }

  if (isDenied) {
    return (
      <ErrorPage
        title={getCopy('campaigns__access_denied')}
        body={getCopy('campaigns__application_access_denied')}
      />
    );
  }
  return <FullPageLoader />;
};
