import { styled } from '@kivra/react-components';

export const CardBlock = styled('div')({
  width: '100%',
  padding: '$spacing-16',
  marginBottom: '$spacing-4',
  borderBottom: `$border-subtle 1px solid`,
  '&:last-child': {
    marginBottom: 0,
    borderBottom: 'none',
  },
});
