/**
 * Assert value being non-null and non-undefined
 */
export function assertDefined<T>(
  value: T,
  errorMsg?: string
): asserts value is Exclude<T, null | undefined> {
  if (value === null || value === undefined) {
    throw new UnexpectedUndefinedError(errorMsg);
  }
}

class UnexpectedUndefinedError extends Error {
  readonly name = 'UnexpectedUndefinedError';
  constructor(msg = 'Unexpected undefined/null value') {
    super(msg);
  }
}

export function getDefinedValue<T>(
  value: T,
  errorMsg?: string
): Exclude<T, null | undefined> {
  assertDefined(value, errorMsg);
  return value;
}

export const isDefined = <T>(
  maybeNullable: T
): maybeNullable is Exclude<T, undefined | null> => {
  return maybeNullable !== null && maybeNullable !== undefined;
};
