import { FocusWrapper, Popover, css, styled } from '@kivra/react-components';
import { QuestionIcon } from '@kivra/react-components/icons';

import React, { useRef, useState } from 'react';

export interface BlockProps {
  tooltipText: string;
}

export const BlockTooltip: React.FC<BlockProps> = ({ tooltipText }) => {
  const [open, setOpen] = useState<boolean>(false);
  const anchorRef = useRef(null);
  return (
    <Root data-component-type="Tooltip">
      <FocusWrapper role="button" onClick={() => setOpen(true)} ref={anchorRef}>
        <QuestionIcon
          aria-expanded={open}
          aria-haspopup
          aria-controls="popover"
          size={16}
        />
      </FocusWrapper>
      <Popover
        ariaLabel="Popover"
        placement="bottom-start"
        isOpen={open}
        onClose={() => setOpen(false)}
        anchorRef={anchorRef}
        drawerOnSmallScreenSize={false}
      >
        <div
          className={css({
            backgroundColor: '$background-secondary',
            borderRadius: '$radius-medium',
            padding: ['$spacing-8', '$spacing-12'],
          })}
        >
          {tooltipText}
        </div>
      </Popover>
    </Root>
  );
};

const Root = styled.div({
  position: 'absolute',
  top: '6px',
  right: '8px',
  cursor: 'pointer',
});
