import React from 'react';
import {
  Flex,
  GreenLogo,
  Heading,
  Margin,
  styled,
  useOnMount,
} from '@kivra/react-components';
import { useOrganizationIds } from '../../context/organizationsContext';
import { getRoute, goTo } from '../../routes/history';
import { useAuthenticatedUser, useConfig } from '../../context/globalContext';
import { CardBlock } from '../../components/card/CardBlock';
import { Card } from '../../components/card/Card';
import { getCopy } from '../../util/copy';
import { OrganizationCard } from './OrganizationCard';

const HR = styled(Margin)({
  width: '100%',
  borderBottom: `1px solid $border-distinct`,
});

export const SelectOrganization = (): React.JSX.Element | null => {
  const { organizationIds } = useOrganizationIds();
  const { isAdmin } = useAuthenticatedUser();
  const config = useConfig();
  const adminOrganizationId = config.sender_backoffice_admin_organization_id;

  useOnMount(() => {
    const organizationId =
      organizationIds.length === 1 ? organizationIds[0] : null;
    if (organizationId) {
      const defaultPage =
        organizationIds[0] === adminOrganizationId
          ? 'ongoing'
          : 'select-sender';
      goTo(
        getRoute({
          id: defaultPage,
          organizationId,
        })
      );
    }
  });

  if (organizationIds.length === 1) {
    return null;
  }

  return (
    <Flex justifyContent="center">
      <Container>
        <CardBlock>
          <GreenLogo href="#" size="small" text="Campaigns" />
        </CardBlock>
        <CardBlock>
          <Margin top={8}>
            <Heading size="medium" align="center">
              {getCopy('campaigns__select_organization')}
            </Heading>
          </Margin>
          {isAdmin && (
            <>
              <Margin top={30} bottom={30}>
                <Flex justifyContent="center">
                  <OrganizationCard
                    organizationId={adminOrganizationId}
                    onClick={() =>
                      goTo(
                        getRoute({
                          id: 'ongoing',
                          organizationId: adminOrganizationId,
                        })
                      )
                    }
                  />
                </Flex>
              </Margin>
              <HR />
            </>
          )}
          <Margin top={30} bottom={30}>
            <Flex justifyContent="center" wrap="wrap">
              {organizationIds
                .filter(id => id !== adminOrganizationId)
                .map(id => (
                  <OrganizationCard
                    key={id}
                    organizationId={id}
                    onClick={() =>
                      goTo(
                        getRoute({ id: 'select-sender', organizationId: id })
                      )
                    }
                  />
                ))}
            </Flex>
          </Margin>
        </CardBlock>
      </Container>
    </Flex>
  );
};

const Container = styled(Card)({
  minWidth: 'min(400px, 100%)',
});
