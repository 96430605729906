import { shadows, styled, StyleException } from '@kivra/react-components';

export const Card = styled.div({
  border: '$border-distinct',
  borderRadius: '$radius-large',
  backgroundColor: '$background-secondary',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '500px',
  marginTop: StyleException('5%'),
  marginLeft: '$spacing-4',
  marginRight: '$spacing-4',
  boxShadow: shadows.elevation.depth1,
});
