import type { FixThisAnyLater } from '@kivra/sdk/types/util';
import { assertDefined } from './assert-defined';

export const keys = <O extends Record<string, unknown>>(o: O): Array<keyof O> =>
  Object.keys(o);

export type ObjKey = string | number;

export const deepRead =
  (path: ObjKey[]) =>
  (obj: Record<ObjKey, FixThisAnyLater>): FixThisAnyLater =>
    path.reduce((acc, key) => {
      return acc[key];
    }, obj);

export const deepSet =
  (path: ObjKey[], value: (oldValue: FixThisAnyLater) => FixThisAnyLater) =>
  <Obj extends Record<string, FixThisAnyLater>>(obj: Obj): Obj => {
    if (path.length === 0) {
      return value(obj);
    }
    const [key, ...rest] = path;
    const emptyObj =
      typeof rest[0] === 'number' ? new Array(rest[0]).fill(undefined) : {};
    assertDefined(key);
    if (Array.isArray(obj) && typeof key === 'number') {
      const newArr = [...obj];
      newArr[key] = deepSet(rest, value)(obj[key] ?? emptyObj);
      return newArr as FixThisAnyLater;
    }
    return {
      ...obj,
      [key]: deepSet(rest, value)(obj[key] ?? emptyObj),
    };
  };

export const traverseObject = <
  T extends Record<string, FixThisAnyLater> | unknown[],
>(
  sourceObject: T | undefined,
  callback: (value: unknown) => unknown
): FixThisAnyLater => {
  if (
    !sourceObject ||
    typeof sourceObject !== 'object' ||
    !Object.keys(sourceObject).length
  ) {
    return callback(sourceObject);
  }

  if (Array.isArray(sourceObject)) {
    return sourceObject.map(i => traverseObject(i, callback));
  }

  const newObject: Record<string, unknown> = {};
  for (const key in sourceObject) {
    newObject[key] = traverseObject(
      sourceObject[key] as FixThisAnyLater,
      callback
    );
  }
  return newObject;
};

/**
 * Strips out the data contents of a base64 data URI
 */
export const stripBase64DataUri = (uri: string): string => {
  const base64DataUriRegExp = /^data:(?:[a-z]+\/[a-z0-9-_+.]+);base64,(.+)$/i;

  return uri.replace(base64DataUriRegExp, (uri, dataStr) => {
    return uri.replace(dataStr, `<${dataStr.length} bytes omitted>`);
  });
};
