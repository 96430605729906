import { Button, TopBar } from '@kivra/react-components';
import { ArrowLeftLongIcon } from '@kivra/react-components/icons';
import React, { useState } from 'react';
import { CampaignActions } from '../../components/CampaignActions';
import { DeleteDialog } from '../../components/DeleteDialog';
import { UnpublishDialog } from '../../components/UnpublishDialog';
import {
  UserProfileDrawer,
  UserProfileDrawerContainer,
} from '../../components/userProfile/UserProfileDrawer';
import { useCampaign } from '../../context/campaignContext';
import { useCreateSenderRoute } from '../../context/senderContext';
import { useCurrentOrganization } from '../../context/sendersContext';
import { getDefaultPrivateRoute, goTo } from '../../routes/history';
import { campaignActionsByStatus } from '../../util/campaignActions';
import { getCopy } from '../../util/copy';

interface Props {
  closeCampaign(): void;
}

export const DetailsTopBar = ({ closeCampaign }: Props): React.JSX.Element => {
  const createRoute = useCreateSenderRoute();
  const { campaign } = useCampaign();
  const { organizationId } = useCurrentOrganization();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openUnpublishDialog, setOpenUnpublishDialog] = useState(false);

  const allActions = campaignActionsByStatus[campaign.clientStatus];
  const buttons = allActions.length === 3 ? allActions : allActions.slice(0, 2);
  const menuItems = allActions.length > 3 ? allActions.slice(2) : undefined;

  return (
    <>
      <TopBar
        hideLogo={true}
        logoHref="#"
        startAdornment={
          <Button variant="link" onClick={closeCampaign}>
            <Button.Icon
              iconComponent={() => <ArrowLeftLongIcon size={24} />}
              color="$text-secondary"
            />
          </Button>
        }
        endAdornment={
          <>
            <CampaignActions
              campaignId={campaign.id}
              menuActions={menuItems}
              buttonActions={buttons}
            />
            <UserProfileDrawerContainer>
              <UserProfileDrawer
                onSenderClicked={(senderKey: string) =>
                  goTo(getDefaultPrivateRoute(organizationId, senderKey))
                }
              />
            </UserProfileDrawerContainer>
          </>
        }
      />
      <DeleteDialog
        campaignId={campaign.id}
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onSuccess={() =>
          goTo(
            createRoute({
              id:
                campaign.clientStatus === 'previous'
                  ? 'previous'
                  : 'ongoing-and-upcoming',
            }),
            false
          )
        }
      />
      <UnpublishDialog
        campaignId={campaign.id}
        bodyText={
          campaign.clientStatus === 'ongoing'
            ? getCopy('campaigns__unpublish_dialog_body__ongoing')
            : getCopy('campaigns__unpublish_dialog_body__upcoming')
        }
        open={openUnpublishDialog}
        onClose={() => setOpenUnpublishDialog(false)}
        onSuccess={() =>
          goTo(
            createRoute({
              id: 'ongoing-and-upcoming',
            }),
            false
          )
        }
      />
    </>
  );
};
