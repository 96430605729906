import type { ClientStatusType } from '@sender-portal-fe/util-shared/src/sdk/campaigns/types/clientStatus';
import type { CampaignAction } from '../components/CampaignActions';

export const campaignActionsByStatus: Record<
  ClientStatusType,
  CampaignAction[]
> = {
  ongoing: ['edit', 'duplicate', 'unpublish-ongoing', 'delete'],
  upcoming: ['edit', 'duplicate', 'unpublish-upcoming', 'delete'],
  draft: ['edit', 'duplicate', 'delete'],
  previous: ['duplicate', 'delete'],
};
