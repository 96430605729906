import { BlockInfoCard, BlockInfoCardLink } from './BlockInfoCard';
import { BlockTooltip } from './BlockTooltip';
import { BlockWrapper } from './BlockWrapper';

export const Block = {
  Wrapper: BlockWrapper,
  Tooltip: BlockTooltip,
  InfoCard: BlockInfoCard,
  InfoCardLink: BlockInfoCardLink,
};
