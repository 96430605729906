import React from 'react';
import {
  Heading,
  GreenLogo,
  Margin,
  Flex,
  styled,
} from '@kivra/react-components';
import {
  useCurrentOrganization,
  useSenders,
} from '../../context/sendersContext';
import { getDefaultPrivateRoute, goTo } from '../../routes/history';
import { CardBlock } from '../../components/card/CardBlock';
import { Senders } from '../../components/userProfile/Senders';
import { Card } from '../../components/card/Card';
import { getCopy } from '../../util/copy';

export const SelectSender = (): React.JSX.Element | null => {
  const senders = useSenders();
  const { organizationId } = useCurrentOrganization();
  const onlySender = senders.length === 1 ? senders[0] : null;
  if (onlySender) {
    // If a user only has access to 1 tenant they are redirected to that tenant.
    goTo(getDefaultPrivateRoute(organizationId, onlySender.key));
    return null;
  }
  return (
    <Flex justifyContent="center">
      <Container>
        <CardBlock>
          <GreenLogo href="#" size="small" text="Campaigns" />
        </CardBlock>
        <CardBlock data-test-id="SelectSenderBlock">
          <Margin top={8} bottom={30}>
            <Heading size="medium" align="center">
              {getCopy('campaigns__select_sender')}
            </Heading>
          </Margin>
          <Margin bottom={30}>
            <Senders
              type="tenant"
              onSenderSelected={senderKey =>
                goTo(getDefaultPrivateRoute(organizationId, senderKey))
              }
            />
            <Senders
              type="store"
              onSenderSelected={senderKey =>
                goTo(getDefaultPrivateRoute(organizationId, senderKey))
              }
            />
          </Margin>
        </CardBlock>
      </Container>
    </Flex>
  );
};

const Container = styled(Card)({
  minWidth: 'min(400px, 100%)',
});
