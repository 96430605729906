import { isAuthenticated, userSession } from '@kivra/sdk/identity';
import type { FixThisAnyLater } from '@kivra/sdk/types/util/any';
import { decodeIdToken } from '@sender-portal-fe/util-shared/src/sdk/authentication/heimdall';
import type {
  HeimdallQueryParameters,
  PersonaIdToken,
} from '@sender-portal-fe/util-shared/src/sdk/authentication/types/heimdall';
import { createContext, useContext } from 'react';
import type { Config } from '../types/config';
import type { UserProfile } from '../types/userProfile';
import { renewAccessToken } from '../util/handleUnauthorizedErrorWrapper';

export interface GlobalContext {
  config: Config;
  global: Window;
  heimdallParameters: HeimdallQueryParameters;
}

export const GlobalContext = createContext<GlobalContext>(
  null as FixThisAnyLater
);

const { Consumer: GlobalContextConsumer, Provider: GlobalContextProvider } =
  GlobalContext;

const hookCreator =
  <T>(map: (gc: GlobalContext) => T) =>
  (): T => {
    const global = useContext(GlobalContext);
    return map(global);
  };

export const useAuthenticatedUser = hookCreator(g => getUserState(g));
export const useConfig = hookCreator(g => g.config);
export const useStaticOrigin = hookCreator(g => g.config.kivra_static_origin);
export const useGlobal = hookCreator(g => g.global);
export const useHeimdallParameters = hookCreator(g => g.heimdallParameters);
export { GlobalContextConsumer, GlobalContextProvider };

const getUserState = (g: GlobalContext): UserProfile => {
  if (!isAuthenticated()) {
    void renewAccessToken();
    return {
      email: '',
      isAdmin: false,
      name: '',
    };
  }

  const user = decodeIdToken<PersonaIdToken>(
    userSession.getSession()?.userId || ''
  );

  const isAdmin =
    user.organizations?.includes(
      g.config.sender_backoffice_admin_organization_id
    ) || false;

  return {
    email: user.email,
    name: user.name,
    isAdmin,
  };
};
