import React from 'react';
import { styled, StyleException } from '@kivra/react-components';
import type { StyleObject } from '@kivra/react-components';
import type { PreviewType } from '../../types/previewType';
import { useStaticOrigin } from '../../context/globalContext';
import { useCalcPixelsString } from '../../context/previewContext';
import {
  desktopHeight,
  phoneHeight,
  phoneWidth,
  desktopWidth,
  phoneHeightPadding,
  phoneWidthPadding,
  desktopWidthPadding,
  desktopPaddingBottom,
  desktopPaddingTop,
} from './utils/constants';

type Props = { previewType: PreviewType; children?: React.ReactNode };

export const Frame = ({ children, previewType }: Props): React.JSX.Element => {
  const calcPixelsString = useCalcPixelsString();
  const dimensions = getDimensions({ calcPixelsString, previewType });
  const staticOrigin = useStaticOrigin();
  const phoneFrameImage = `${staticOrigin}/assets/illustrations/campaigns/preview-phone-frame.svg`;
  const desktopFrameImage = `${staticOrigin}/assets/illustrations/campaigns/preview-desktop-frame.svg`;
  const frame = previewType === 'desktop' ? desktopFrameImage : phoneFrameImage;
  return (
    <Wrapper {...dimensions}>
      <FrameImage alt="" src={frame} {...dimensions} />
      <ChildWrapper dimensions={dimensions}>{children}</ChildWrapper>
    </Wrapper>
  );
};

type Dimensions = Pick<
  StyleObject,
  | 'height'
  | 'width'
  | 'paddingRight'
  | 'paddingBottom'
  | 'paddingLeft'
  | 'paddingTop'
>;

interface StyledProps {
  calcPixelsString: (pixels: number) => string;
  previewType: PreviewType;
}

const getDimensions = ({
  calcPixelsString,
  previewType,
}: StyledProps): Dimensions =>
  previewType === 'desktop'
    ? {
        height: calcPixelsString(desktopHeight),
        width: calcPixelsString(desktopWidth),
        paddingRight: calcPixelsString(desktopWidthPadding),
        paddingBottom: calcPixelsString(desktopPaddingBottom),
        paddingLeft: calcPixelsString(desktopWidthPadding),
        paddingTop: calcPixelsString(desktopPaddingTop),
      }
    : {
        height: calcPixelsString(phoneHeight),
        width: calcPixelsString(phoneWidth),
        paddingRight: StyleException(calcPixelsString(phoneWidthPadding)),
        paddingBottom: StyleException(calcPixelsString(phoneHeightPadding)),
        paddingLeft: StyleException(calcPixelsString(phoneWidthPadding)),
        paddingTop: StyleException(calcPixelsString(phoneHeightPadding)),
      };

const Wrapper = styled('div', {
  forwardProp: prop => prop === 'children',
})<Dimensions>(({ height, width }) => ({
  position: 'relative',
  backgroundColor: '!!$black',
  overflow: 'hidden',
  height,
  width,
  margin: StyleException('$spacing-48 auto $spacing-24'),
}));

const FrameImage = styled('img', {
  forwardProp: prop => ['alt', 'src'].includes(prop),
})<Dimensions>(({ height, width }) => ({
  position: 'absolute',
  height,
  width,
}));

const ChildWrapper = styled('div', {
  forwardProp: prop => prop === 'children',
})<{ dimensions: Dimensions }>(({ dimensions }) => ({
  display: 'flex',
  ...dimensions,
}));
