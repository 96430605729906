import { FocusWrapper } from '@kivra/react-components';
import { EyeIcon, EyeClosedIcon } from '@kivra/react-components/icons';
import React from 'react';

export const VisibilityToggle = ({
  isVisible,
  onClick,
}: {
  isVisible: boolean;
  onClick: (visible: boolean) => void;
}): React.JSX.Element => {
  return (
    <FocusWrapper role="button" onClick={() => onClick(!isVisible)}>
      {React.createElement(isVisible ? EyeIcon : EyeClosedIcon, {
        colorToken: 'currentColor',
        size: 16,
        style: { cursor: 'pointer' },
      })}
    </FocusWrapper>
  );
};
