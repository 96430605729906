import {
  Body,
  Flex,
  Margin,
  styled,
  useOnMount,
} from '@kivra/react-components';
import { KivraIcon } from '@kivra/react-components/icons';
import { getOrganization } from '@sender-portal-fe/util-shared/src/sdk/senders/organizations';
import { getOrganizationSenders } from '@sender-portal-fe/util-shared/src/sdk/senders/senders';
import React, { useState } from 'react';
import { useConfig } from '../../context/globalContext';
import { getCopy } from '../../util/copy';

type Props = {
  organizationId: string;
  onClick: () => void;
};

const Base = styled('div')({
  width: 130,
  textOverflow: 'ellipsis',
  margin: '$spacing-8',
  textAlign: 'center',
  borderRadius: '$radius-medium',
  boxShadow: '$shadow-floating',
  padding: '$spacing-16',
  cursor: 'pointer',
  '&:focus': {
    outline: 'none',
  },
  '.keyboardFocus &:focus': {
    outline: `2px auto $focus-border`,
  },
  '&:hover': {
    boxShadow: '$shadow-hover-list',
  },
});

export const OrganizationCard = ({
  organizationId,
  onClick,
}: Props): React.JSX.Element | null => {
  const [iconUrl, setIconUrl] = useState<string | undefined>();
  const [organizationName, setOrganizationName] = useState('');
  const adminOrganizationId =
    useConfig().sender_backoffice_admin_organization_id;

  useOnMount(() => {
    void Promise.all([
      getOrganization(organizationId),
      getOrganizationSenders(organizationId),
    ]).then(([{ name }, senders]) => {
      setIconUrl(senders[0]?.iconUrl);
      setOrganizationName(name);
    });
  });

  if (organizationId === adminOrganizationId) {
    return (
      <Base role="button" onClick={onClick} tabIndex={0}>
        <Flex direction="column" alignItems="center" justifyContent="center">
          <Margin top={11} right={11} left={11} bottom={11}>
            <KivraIcon size={48} colorToken="$green-300" />
          </Margin>
          <Body size="small">
            {getCopy('campaigns__kivra_backoffice_admin')}
          </Body>
        </Flex>
      </Base>
    );
  }

  if (!iconUrl) {
    return null;
  }

  return (
    <Base role="button" onClick={onClick} tabIndex={0}>
      <Flex direction="column" alignItems="center" justifyContent="center">
        <img width={70} height={70} alt={'Tenant icon'} src={iconUrl} />
        <Body size="small">{organizationName}</Body>
      </Flex>
    </Base>
  );
};
