import React from 'react';
import { Button, ErrorPage } from '@kivra/react-components';
import { getRoute, goTo } from '../../routes/history';
import { getCopy } from '../../util/copy';

export const NoMatch = (): React.JSX.Element => {
  return (
    <ErrorPage title={getCopy('error_generic__title')}>
      <Button
        size="medium"
        onClick={() => goTo(getRoute({ id: 'select-organization' }))}
      >
        {getCopy('campaigns__goto_startpage_button')}
      </Button>
    </ErrorPage>
  );
};
