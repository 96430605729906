import { createContext, useContext } from 'react';

export interface OrganizationsContext {
  organizationIds: string[];
  setOrganizationIds: (ids: string[]) => void;
}

export const OrganizationsContext = createContext<OrganizationsContext>({
  organizationIds: [],
  setOrganizationIds: (ids) => {
    console.warn('setOrganizationIds is not implemented', ids);
  },
});

const {
  Consumer: OrganizationContextConsumer,
  Provider: OrganizationContextProvider,
} = OrganizationsContext;

const hookCreator = <T>(map: (context: OrganizationsContext) => T) => (): T => {
  const organizations = useContext(OrganizationsContext);
  return map(organizations);
};

export const useOrganizationIds = hookCreator(
  ({ organizationIds, setOrganizationIds }) => ({
    organizationIds,
    setOrganizationIds,
  })
);

export { OrganizationContextProvider, OrganizationContextConsumer };
