import { Caption, Flex, styled } from '@kivra/react-components';
import { motion, useReducedMotion } from 'framer-motion';
import type { PropsWithChildren } from 'react';
import React from 'react';
import { getCopy } from '../../../../../util/copy';
import { BlockTooltip } from './BlockTooltip';

export interface BlockProps {
  tooltipText?: string;
  label?: string;
  optional?: boolean;
  dataTestId?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

// TODO: Remove optional, label, tooltipText and add them to ./Block.
export const BlockWrapper = ({
  children,
  dataTestId,
  label,
  optional,
  tooltipText,
  style,
}: BlockProps): React.JSX.Element => {
  return (
    <Animate>
      <Root data-component-type="Block" data-test-id={dataTestId} style={style}>
        <Flex justifyContent="space-between">
          <Caption>{label}</Caption>
          {optional && (
            <Caption style={{ fontStyle: 'italic' }}>
              {getCopy('campaigns__optional')}
            </Caption>
          )}
        </Flex>
        {tooltipText && <BlockTooltip tooltipText={tooltipText} />}
        {children}
      </Root>
    </Animate>
  );
};

const Animate: React.FC<PropsWithChildren> = ({ children }) => {
  const reducedMotion = useReducedMotion();

  return (
    <motion.div
      layout={reducedMotion ? false : 'position'}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: 'easeInOut', duration: 0.5 }}
    >
      {children}
    </motion.div>
  );
};

export const Root = styled.div({
  position: 'relative',
  padding: ['$spacing-16', '$spacing-24'],
  borderTop: '1px solid $border-distinct',
});
