import { ApiResponseError } from '@kivra/sdk/common';
import type { FixThisAnyLater } from '@kivra/sdk/types/util/any';
import {
  activateCampaign as activateCampaignFeSdk,
  createCampaign as createCampaignsFeSdk,
  deactivateCampaign as deactivateCampaignFeSdk,
  deleteCampaign as deleteCampaignFeSdk,
  getAdminOrganizationCampaigns as getAdminOrganizationCampaignsFeSdk,
  getCampaign as getCampaignFeSdk,
  getCampaignStatistics as getCampaignStatisticsFeSdk,
  getCampaigns as getCampaignsFeSdk,
  getIsSsoEnabled as getIsSsoEnabledFeSdk,
  getOrganizationCampaigns as getOrganizationCampaignsFeSdk,
  getOverlappingCampaigns as getOverlappingCampaignsFeSdk,
  updateCampaign as updateCampaignFeSdk,
} from '@sender-portal-fe/util-shared/src/sdk/campaigns/campaigns';
import {
  createSegmentationJob as createSegmentationJobFeSdk,
  deleteSegmentation as deleteSegmentationFeSdk,
  getSegmentationJobStatus as getSegmentationJobStatusFeSdk,
} from '@sender-portal-fe/util-shared/src/sdk/campaigns/segmentation';
import { logoutUser } from './authentication';

export const renewAccessToken = async (): Promise<void> => {
  logoutUser({ shouldClearTenantStorage: false, shouldNavigate: false });
  window.location.reload();
};

export const handleUnauthenticatedRequest =
  <
    OriginalRequest extends (
      ...args: FixThisAnyLater[]
    ) => Promise<FixThisAnyLater>,
  >(
    request: OriginalRequest
  ) =>
  (...args: Parameters<typeof request>): ReturnType<OriginalRequest> =>
    request(...args).catch((error: unknown) => {
      if (error instanceof ApiResponseError && error.httpStatusCode === 401) {
        void renewAccessToken();
      } else {
        throw error;
      }
    }) as ReturnType<OriginalRequest>;

export const activateCampaign = handleUnauthenticatedRequest(
  activateCampaignFeSdk
);

export const getCampaign = handleUnauthenticatedRequest(getCampaignFeSdk);

export const createCampaign =
  handleUnauthenticatedRequest(createCampaignsFeSdk);

export const updateCampaign = handleUnauthenticatedRequest(updateCampaignFeSdk);

export const deleteCampaign = handleUnauthenticatedRequest(deleteCampaignFeSdk);

export const deactivateCampaign = handleUnauthenticatedRequest(
  deactivateCampaignFeSdk
);

export const getCampaigns = handleUnauthenticatedRequest(getCampaignsFeSdk);

export const getOrganizationCampaigns = handleUnauthenticatedRequest(
  getOrganizationCampaignsFeSdk
);

export const getAdminOrganizationCampaigns = handleUnauthenticatedRequest(
  getAdminOrganizationCampaignsFeSdk
);

export const deleteSegmentation = handleUnauthenticatedRequest(
  deleteSegmentationFeSdk
);

export const createSegmentationJob = handleUnauthenticatedRequest(
  createSegmentationJobFeSdk
);

export const getSegmentationJobStatus = handleUnauthenticatedRequest(
  getSegmentationJobStatusFeSdk
);

export const getOverlappingCampaigns = handleUnauthenticatedRequest(
  getOverlappingCampaignsFeSdk
);

export const getCampaignStatistics = handleUnauthenticatedRequest(
  getCampaignStatisticsFeSdk
);

export const getIsSsoEnabled =
  handleUnauthenticatedRequest(getIsSsoEnabledFeSdk);
