import {
  TextAlignCenterIcon,
  TextAlignLeftIcon,
  TextAlignRightIcon,
} from '@kivra/react-components/icons';
import React from 'react';
import { Flex } from '@kivra/react-components';
import type { HorizontalAlignment } from '../../../types/alignment';
import { Selectable } from './Selectable';

type Props = {
  alignment: HorizontalAlignment;
  onChange: (alignment: HorizontalAlignment) => void;
};

const icons: Record<HorizontalAlignment, React.JSX.Element> = {
  left: <TextAlignLeftIcon size={16} colorToken="currentColor" />,
  center: <TextAlignCenterIcon size={16} colorToken="currentColor" />,
  right: <TextAlignRightIcon size={16} colorToken="currentColor" />,
};

const alignments: HorizontalAlignment[] = ['left', 'center', 'right'];

export const AlignmentSelector = (props: Props): React.JSX.Element => {
  return (
    <Flex>
      {alignments.map(alignment => (
        <Selectable
          key={alignment}
          selected={alignment === props.alignment}
          onClick={() => props.onChange(alignment)}
          deSelectable={false}
        >
          {icons[alignment]}
        </Selectable>
      ))}
    </Flex>
  );
};
