import {
  Button,
  Margin,
  NavDrawerMenu,
  styled,
  useIsMediumScreenSize,
} from '@kivra/react-components';
import {
  PlaySquareFilledIcon,
  PlaySquareIcon,
  PlusIcon,
  PreviousIcon,
  DocumentSignIcon,
  DocumentSignFilledIcon,
} from '@kivra/react-components/icons';
import React from 'react';
import { CampaignsNavDrawer } from '../../../components/CampaignsNavDrawer';
import {
  useCreateSenderRoute,
  useSender,
} from '../../../context/senderContext';
import { goTo } from '../../../routes/history';
import type { DashboardType } from '../../../types/campaignList';
import { getCopy } from '../../../util/copy';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedMenuType: DashboardType;
}

export const dashboardNavDrawerWidth = 300;

export const DashboardDrawer = ({
  open,
  onClose,
  selectedMenuType,
}: Props): React.JSX.Element => {
  const { type: senderType } = useSender();
  const createButtonRef = React.useRef<HTMLButtonElement>(null);
  const createRoute = useCreateSenderRoute();
  const isMediumSize = useIsMediumScreenSize();
  const onClick = (id: string): void => {
    goTo(createRoute({ id } as { id: DashboardType }));
  };

  const menu = [
    {
      id: 'ongoing-and-upcoming',
      icon: {
        initial: PlaySquareIcon,
        selected: PlaySquareFilledIcon,
      },
      label: `${getCopy('campaigns__status__ongoing')} & ${getCopy(
        'campaigns__status__upcoming'
      )}`,
    },
    {
      id: 'draft',
      icon: {
        initial: DocumentSignIcon,
        selected: DocumentSignFilledIcon,
      },
      label: getCopy('campaigns__status__draft'),
    },
    {
      id: 'previous',
      icon: { initial: PreviousIcon, selected: PreviousIcon },
      label: getCopy('campaigns__status__previous'),
    },
  ];

  return (
    <CampaignsNavDrawer
      variant={isMediumSize ? 'temporary' : 'permanent'}
      open={open}
      onClose={onClose}
      width={dashboardNavDrawerWidth}
      transparent={!isMediumSize}
    >
      <Margin left={16} right={16} bottom={16} top={12}>
        <CreateButton
          ref={createButtonRef}
          onClick={() => {
            goTo(
              createRoute({
                id: 'new-campaign',
                campaignType: senderType === 'tenant' ? 'postal' : 'receipt',
              })
            );
          }}
          variant="primary"
          size="medium"
        >
          <Button.Icon iconComponent={PlusIcon} />
          {getCopy('btn__create_new')}
        </CreateButton>
      </Margin>
      <NavDrawerMenu
        variant="secondary"
        onClick={onClick}
        selectedId={selectedMenuType}
        menuItems={menu}
      />
    </CampaignsNavDrawer>
  );
};

const CreateButton = styled(Button)({ minWidth: '100%' });
