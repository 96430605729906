import { css } from '@kivra/react-components';
import React from 'react';
import { useStaticOrigin } from '../../context/globalContext';
import { useCalcPixelsString } from '../../context/previewContext';
import { LayoutBase } from './utilComponents';
import { phoneInnerHeight } from './utils/constants';
import { CampaignImage } from './CampaignImage';

export const ReceiptLayout = (): React.JSX.Element => {
  const calcPixelsString = useCalcPixelsString();
  const staticOrigin = useStaticOrigin();
  const priceImage = `${staticOrigin}/assets/illustrations/campaigns/preview-receipts-price.png`;
  const productsImage = `${staticOrigin}/assets/illustrations/campaigns/preview-receipts-products.png`;
  return (
    <LayoutBase
      className={css({
        backgroundColor: '$background-primary',
        display: 'flex',
        flexDirection: 'column',
        minHeight: calcPixelsString(phoneInnerHeight),
      })}
    >
      <img style={{ width: '100%' }} src={priceImage} alt="receipt price" />
      <div style={{ padding: calcPixelsString(8) }}>
        <img
          style={{ borderRadius: calcPixelsString(8), width: '100%' }}
          src={productsImage}
          alt="receipt price"
        />
        <CampaignImage fallbackImagePath="/assets/illustrations/campaigns/preview-receipts-fallback.png" />
      </div>
    </LayoutBase>
  );
};
