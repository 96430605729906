import { Dialog, ErrorCard } from '@kivra/react-components';
import { AlertFilledIcon } from '@kivra/react-components/icons';
import type { ApiResponseError } from '@kivra/sdk/common';
import React, { useState } from 'react';
import { useCampaign } from '../../../../../context/campaignContext';
import { getCopy } from '../../../../../util/copy';

interface Props {
  onClose: () => void;
  onSuccess: () => void;
}

export const DeleteDialog = ({
  onClose,
  onSuccess,
}: Props): React.JSX.Element => {
  const { deleteSegmentation } = useCampaign();
  const [error, setError] = useState<ApiResponseError>();

  const deleteHandler = (): Promise<void> => {
    return deleteSegmentation()
      .then(onSuccess)
      .catch((error: unknown) => setError(error as ApiResponseError));
  };

  const errorElement = error && (
    <ErrorCard severity="low">
      <ErrorCard.Title>
        {getCopy('campaigns__delete_segmentation_error')}
      </ErrorCard.Title>
      <ErrorCard.Text>
        {getCopy('campaigns__dialog_error_generic_body')}
      </ErrorCard.Text>
    </ErrorCard>
  );
  return (
    <Dialog.Destructive
      actionText={getCopy('btn__delete')}
      cancelText={getCopy('btn__cancel_dialog')}
      onConfirm={deleteHandler}
      onClose={onClose}
      title={getCopy('campaigns__delete_segmentation_title')}
      open={true}
      icon={<AlertFilledIcon size={24} colorToken="$error-text" />}
      ariaLabelCloseIcon={getCopy('btn__cancel_dialog')}
      onDismissFocusRef={undefined}
    >
      {errorElement}
    </Dialog.Destructive>
  );
};
