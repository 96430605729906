import { Button, TopBar } from '@kivra/react-components';
import { ArrowLeftLongIcon } from '@kivra/react-components/icons';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CampaignActions } from '../../../../components/CampaignActions';
import { TopBarButton } from '../../../../components/TopBarButton';
import {
  UserProfileDrawer,
  UserProfileDrawerContainer,
} from '../../../../components/userProfile/UserProfileDrawer';
import {
  useCampaign,
  useCampaignStatus,
} from '../../../../context/campaignContext';
import { useCreateSenderRoute } from '../../../../context/senderContext';
import { useCurrentOrganization } from '../../../../context/sendersContext';
import {
  getDefaultPrivateRoute,
  goBack,
  goTo,
} from '../../../../routes/history';
import type {
  CampaignForm,
  CampaignFormErrors,
} from '../../../../types/campaignForm';
import { campaignActionsByStatus } from '../../../../util/campaignActions';
import { getCopy } from '../../../../util/copy';
import { UnsavedChangesDialog } from './UnsavedChangesDialog';
import {
  areErrorsAcceptableInDraft,
  isFormDirty,
} from './utils/campaignEditor';

interface Props {
  submitHandler(): void;
}

interface ExitState {
  showModal: boolean;
  onExit?(fallbackRoute?: string): void;
}

export const EditorTopBar = ({ submitHandler }: Props): React.JSX.Element => {
  const form = useFormContext<CampaignForm>();
  const { control, formState } = form;
  const campaignStatus = useCampaignStatus();
  const { campaign } = useCampaign(false);
  const createRoute = useCreateSenderRoute();
  const { organizationId } = useCurrentOrganization();

  const [exitState, setExitState] = useState<ExitState>({ showModal: false });
  const disableSaveDraft = !areErrorsAcceptableInDraft(
    formState.errors as CampaignFormErrors
  );
  // If the campaign has not been saved we should always allow saving
  const disableSave =
    Boolean(campaign) && (disableSaveDraft || !isFormDirty(formState));
  const isDraft = campaignStatus === 'draft';

  const onExitHandler = (onExit = () => goBack(dashboardRoute)): void => {
    if (isFormDirty(formState)) {
      setExitState({ showModal: true, onExit });
    } else {
      onExit();
    }
  };

  const dashboardRoute = createRoute({
    id: isDraft ? 'draft' : 'ongoing-and-upcoming',
  });

  const gotoDashboard = (): void => goTo(dashboardRoute);

  return (
    <>
      <TopBar
        logoHref="#"
        hideLogo={true}
        startAdornment={
          <Button variant="link" onClick={() => onExitHandler()} size="medium">
            <Button.Icon
              iconComponent={() => <ArrowLeftLongIcon size={24} />}
              color="$text-secondary"
            />
          </Button>
        }
        endAdornment={
          <>
            {campaignStatus === 'ongoing' && (
              <TopBarButton
                variant="secondary"
                size="small"
                onClick={() => onExitHandler()}
              >
                {getCopy('btn__cancel_dialog')}
              </TopBarButton>
            )}
            <Controller
              control={control}
              name="submitActionType"
              render={props => (
                <>
                  {campaignStatus === 'draft' && (
                    <TopBarButton
                      size="small"
                      onClick={() => {
                        props.field.onChange('save');
                        submitHandler();
                      }}
                      progress={
                        formState.isSubmitting && props.field.value === 'save'
                      }
                      variant="secondary"
                      disabled={disableSave}
                    >
                      {getCopy('btn__save_draft')}
                    </TopBarButton>
                  )}
                  <TopBarButton
                    size="small"
                    disabled={!isDraft && disableSave}
                    onClick={async () => {
                      props.field.onChange('publish');
                      submitHandler();
                    }}
                    progress={
                      formState.isSubmitting && props.field.value === 'publish'
                    }
                  >
                    {getCopy(isDraft ? 'btn__publish' : 'btn__update')}
                  </TopBarButton>
                </>
              )}
            />
            <AnimateEditMenuPresence>
              {campaign && (
                <CampaignActions
                  campaignId={campaign.id}
                  menuActions={campaignActionsByStatus[campaignStatus].filter(
                    action => action !== 'edit'
                  )}
                  onDeleteSuccess={gotoDashboard}
                  onUnpublishSuccess={gotoDashboard}
                />
              )}
            </AnimateEditMenuPresence>
            <UserProfileDrawerContainer>
              <UserProfileDrawer
                onSenderClicked={senderKey =>
                  onExitHandler(() =>
                    goTo(getDefaultPrivateRoute(organizationId, senderKey))
                  )
                }
              />
            </UserProfileDrawerContainer>
          </>
        }
      />
      <UnsavedChangesDialog
        open={exitState.showModal}
        onClose={() => setExitState({ showModal: false, onExit: undefined })}
        onAccept={exitState.onExit}
      />
    </>
  );
};

type EditMenuProps = {
  children?: React.ReactNode;
};

const AnimateEditMenuPresence = ({
  children,
}: EditMenuProps): React.JSX.Element => (
  <AnimatePresence initial={false}>
    {children && (
      <motion.div
        initial={{ opacity: 0, width: 0 }}
        animate={{ opacity: 100, width: 'auto' }}
        exit={{ opacity: 0, width: 0 }}
        transition={{
          easing: 'linear',
          duration: 0.2,
        }}
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);
