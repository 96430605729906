import { Caption, Heading, Margin, styled } from '@kivra/react-components';
import React from 'react';
import { CampaignReadonlyDetails } from '../../components/CampaignReadonlyDetails';
import { InformationSurface } from '../../components/InformationSurface';
import { Content } from '../../components/campaignLayout/Content';
import { Main } from '../../components/campaignLayout/Main';
import { SideBar } from '../../components/campaignLayout/SideBar';
import { Preview } from '../../components/preview';
import { Statistics } from '../../components/statistics/Statistics';
import { useCampaign } from '../../context/campaignContext';
import { getDefaultPrivateRoute, goBack } from '../../routes/history';
import { getCopy } from '../../util/copy';
import { DetailsTopBar } from './DetailsTopBar';

export const CampaignDetails = (): React.JSX.Element => {
  const { campaign } = useCampaign();

  const closeCampaign = (): void => {
    goBack(
      getDefaultPrivateRoute(campaign.organization.key, campaign.sender.key)
    );
  };

  return (
    <>
      <DetailsTopBar closeCampaign={closeCampaign} />
      <Main>
        <SideBar>
          {campaign.clientStatus === 'previous' && (
            <Margin top={16} left={8} right={8}>
              <InformationSurface data-test-id="DetailsCannotBeEdited">
                <Caption>{getCopy('campaigns__cannot_be_edited')}</Caption>
              </InformationSurface>
            </Margin>
          )}
          <DetailsWrapper data-test-id="DetailsSideBar">
            <CampaignReadonlyDetails campaign={campaign} />
          </DetailsWrapper>
        </SideBar>
        <Content>
          <StatisticsPadding>
            <Heading size="medium">{getCopy('campaigns__overview')}</Heading>
            <Statistics campaign={campaign} />
          </StatisticsPadding>
          <Preview campaign={campaign} />
        </Content>
      </Main>
    </>
  );
};

const DetailsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '$spacing-16',
  padding: '$spacing-20',
});

const StatisticsPadding = styled.div({
  backgroundColor: '$background-secondary',
  padding: ['$spacing-32', '$spacing-16'],
});
