import React, { useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { topbarHeight, Margin, css } from '@kivra/react-components';
import type { DashboardType } from '../../types/campaignList';
import { DashboardTopBar } from '../../components/DashboardTopBar';
import { DashboardDrawer } from './components/DashboardDrawer';
import { Campaigns } from './components/Campaigns';

interface MatchParams {
  dashboardType: DashboardType;
}

export const Dashboard = ({
  match,
}: RouteComponentProps<MatchParams>): React.JSX.Element => {
  const selectedDashboard = match.params.dashboardType;

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = (): void => {
    setDrawerOpen(!drawerOpen);
  };

  const ongoingUpcomingElement = selectedDashboard ===
    'ongoing-and-upcoming' && <Campaigns types={['ongoing', 'upcoming']} />;
  const draftElement = selectedDashboard === 'draft' && (
    <Campaigns types={['draft']} />
  );
  const previousElement = selectedDashboard === 'previous' && (
    <Campaigns types={['previous']} />
  );

  return (
    <>
      <DashboardTopBar
        drawerOpen={drawerOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <div
        className={css({
          display: 'flex',
          height: `calc(100% - ${topbarHeight}px)`,
        })}
      >
        <DashboardDrawer
          open={drawerOpen}
          onClose={handleDrawerToggle}
          selectedMenuType={selectedDashboard}
        />
        <main style={{ flexGrow: 1, overflowX: 'auto' }}>
          <Margin top={12} left={24} right={24} bottom={100}>
            {ongoingUpcomingElement}
            {draftElement}
            {previousElement}
          </Margin>
        </main>
      </div>
    </>
  );
};
