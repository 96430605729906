import React from 'react';
import { useCampaignType } from '../../../../context/campaignContext';
import { CampaignDatesBlocks } from './fields/CampaignDatesBlocks';
import { DocumentTypeField } from './fields/DocumentTypeField';
import { ImageField } from './fields/ImageField';
import { LinkField } from './fields/LinkField';
import { PositionField } from './fields/PositionField';
import { TagNameField } from './fields/TagNameField';
import { TagSwitch } from './fields/TagSwitch';
import { TargetGroupField } from './fields/TargetGroupField';
import { TitleField } from './fields/TitleField';

export const receiptForm = [
  TitleField,
  DocumentTypeField,
  ImageField,
  CampaignDatesBlocks,
  LinkField,
] as const;

const postalForm = [
  TagSwitch,
  TitleField,
  DocumentTypeField,
  PositionField,
  ImageField,
  CampaignDatesBlocks,
  LinkField,
  TargetGroupField,
] as const;

const byTagForm = [
  TagSwitch,
  TagNameField,
  TitleField,
  PositionField,
  ImageField,
  CampaignDatesBlocks,
  LinkField,
] as const;

const fields = {
  receipt: receiptForm,
  postal: postalForm,
  'by-tag': byTagForm,
} as const;

export const Editor = React.memo((): React.JSX.Element => {
  const { campaignType } = useCampaignType();
  return (
    <form>
      {fields[campaignType].map(field =>
        React.createElement(field, { key: field.name })
      )}
    </form>
  );
});
