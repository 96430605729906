import React from 'react';
import { Caption, Margin, styled } from '@kivra/react-components';
import type { ImageError } from '../../../../types/editorValidationErrors';
import { getCopy } from '../../../../util/copy';
import { handleImageFile, maxWeightInKB, minWidth } from './utils/uploadInput';
import { UploadButton } from './UploadButton';
import { FormErrorMessage } from './FormErrorMessage';

export type ImageChangeEvent = {
  value?: string;
  error?: ImageError;
};

interface Props {
  disabled?: boolean;
  errorMessage?: string;
  onChange: (event: ImageChangeEvent) => void;
  isReceipt: boolean;
}

export const ImageUpload = ({
  disabled,
  errorMessage,
  onChange,
  isReceipt,
}: Props): React.JSX.Element => {
  const copy = getImageCopy(isReceipt);
  return (
    <>
      <Caption align="center" color="$text-secondary">
        {`${copy.format} `}
        <Bold>{copy.validImageTypes}</Bold>
        {` ${copy.imageWeight} `}
        {copy.minSize}
        <Bold>{` ${copy.imageSize} `}</Bold>
        {copy.ratio}
        <Bold>{` ${copy.ratioValues}`}</Bold>
      </Caption>
      <Margin top={16}>
        <UploadButton<ImageChangeEvent>
          validFileTypes=".png,.jpeg,.jpg"
          handleFiles={handleImageFile}
          onChange={onChange}
          disabled={disabled}
        />
      </Margin>
      {errorMessage && <FormErrorMessage errorMessage={errorMessage} />}
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getImageCopy = (isReceipt: boolean) => ({
  format: getCopy('campaigns__format'),
  validImageTypes: getCopy('campaigns__valid_image_types'),
  minSize: getCopy(
    isReceipt
      ? 'campaigns__image_min_size_receipt'
      : 'campaigns__image_min_width'
  ),
  imageWeight: getCopy('campaigns__image_max_size', {
    size: String(maxWeightInKB),
  }),
  imageSize: isReceipt
    ? getCopy('campaigns__image_size_receipt')
    : `${minWidth}px`,
  ratio: getCopy('campaigns__ratio'),
  ratioValues: getCopy(
    isReceipt ? 'campaigns__ratio_receipt' : 'campaigns__ratio_tenant'
  ),
});

const Bold = styled('span')({
  color: '$text-primary',
  fontWeight: 'bold',
});
