import { Caption, css } from '@kivra/react-components';
import React from 'react';

type Props = {
  errorMessage: string;
};

export const FormErrorMessage = ({
  errorMessage,
}: Props): React.JSX.Element => {
  return (
    <div className={css({ width: '100%', marginTop: '$spacing-4' })}>
      <Caption color="$error-text">{errorMessage}</Caption>
    </div>
  );
};
