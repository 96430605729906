import { Body, Dialog, zIndex, css } from '@kivra/react-components';
import React from 'react';
import { getCopy } from '../../../../util/copy';

interface Props {
  open?: boolean;
  onClose: () => void;
  onAccept?: () => void;
}

export const UnsavedChangesDialog = (props: Props): React.JSX.Element => {
  const { open = true, onClose, onAccept } = props;

  /**
   * The wrapping `<div />` is a temporary fix for the z-index
   * issue with multiple dialogs overlapping each other.
   *
   * TODO: A nicer fix should be applied to the dialog components
   * in `@kivra/react-components`.
   *
   * TODO: Ideally the fix should also handle the initial focus
   * being set on dialogs, in the case of more `[role="dialog"]`
   * existing in the DOM.
   */
  return (
    <div className={css({ zIndex: zIndex.modal })}>
      <Dialog.Confirmation
        actionText={getCopy('sender_portal__dont_save')}
        cancelText={getCopy('sender_portal__keep_editing')}
        onConfirm={() => onAccept && onAccept()}
        onClose={onClose}
        title={getCopy('sender_portal__unsaved_changes')}
        open={open}
        ariaLabelCloseIcon={getCopy('btn__cancel_dialog')}
        onDismissFocusRef={undefined}
      >
        <Body size="medium">
          {getCopy('sender_portal__unsaved_changes_question')}
        </Body>
      </Dialog.Confirmation>
    </div>
  );
};
