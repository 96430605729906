type ValidateUrlSuccess = {
  success: true;
};

type ValidateUrlFailure = {
  success: false;
  reason: 'https' | 'illegalCharacters' | 'format';
};

/**
 * Temp export to let campaigns use the same RegExp
 */
export const ILLEGAL_URL_CHARS_REGEXP =
  /[^A-ZÅÄÖa-zåäö0-9:/?#[\]@!$&'()*+,;=.\-_~%]/g;

export const validateUrl = (
  value: string
): ValidateUrlSuccess | ValidateUrlFailure => {
  if (!value.startsWith('https://')) {
    return { success: false, reason: 'https' };
  }

  const illegalCharacters = value.match(ILLEGAL_URL_CHARS_REGEXP);
  if (illegalCharacters && illegalCharacters.length > 0) {
    return { success: false, reason: 'illegalCharacters' };
  }

  if (!isValidUrl(value)) {
    return { success: false, reason: 'format' };
  }

  return { success: true };
};

const isValidUrl = (text: string): boolean => {
  let url;
  try {
    url = new URL(text);
  } catch {
    return false;
  }

  return url.protocol === 'https:';
};
