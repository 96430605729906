import {
  AnimatedItem,
  ErrorCard,
  Flex,
  StatusCard,
  styled,
} from '@kivra/react-components';
import React, { useEffect } from 'react';
import type { CampaignFormErrors } from '../../../../types/campaignForm';
import type { PreviewFeedbackState } from '../../../../types/previewFeedbackType';
import { getCopy } from '../../../../util/copy';
import { getServiceErrorCopy } from '../../../../util/copyKeys';

interface Props {
  state: PreviewFeedbackState;
  onClose: (previewType?: PreviewFeedbackState) => void;
  formErrors: CampaignFormErrors;
}

export const PreviewFeedback = ({
  state,
  onClose,
  formErrors,
}: Props): React.JSX.Element => {
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (state.type === 'serviceSuccess') {
      timeout = setTimeout(() => {
        onClose();
      }, 3000);
    } else if (
      (state.type === 'publishError' || state.type === 'saveDraftError') &&
      Object.keys(formErrors).length === 0
    ) {
      onClose();
    }
    return () => clearTimeout(timeout);
  }, [state, formErrors, onClose]);

  return (
    <StyledAnimatedItem>
      <Flex justifyContent="flex-end" data-test-id="PreviewFeedback">
        <FeedbackCard state={state} formErrors={formErrors} />
      </Flex>
    </StyledAnimatedItem>
  );
};

const FeedbackCard = ({
  state,
  formErrors,
}: Pick<Props, 'formErrors' | 'state'>): React.JSX.Element => {
  switch (state.type) {
    case 'serviceSuccess':
      return (
        <StatusCard variant="success">
          <StatusCard.Title>
            {getCopy('campaigns__save_campaign_success_title')}
          </StatusCard.Title>
        </StatusCard>
      );
    case 'serviceError':
      return (
        <ErrorCard severity="high">
          <ErrorCard.Title>
            {getCopy('campaigns__save_campaign_error_title')}
          </ErrorCard.Title>
          <ErrorCard.Text>
            {getCopy(getServiceErrorCopy(state.error))}
          </ErrorCard.Text>
        </ErrorCard>
      );
    case 'publishError':
    case 'saveDraftError':
      return (
        <ErrorCard severity="high">
          <ErrorCard.Title>
            {getCopy(
              state.type === 'publishError'
                ? 'campaigns__publish_error_header'
                : 'campaigns__save_draft_error_header'
            )}
          </ErrorCard.Title>
          {getErrorMessage({ formErrors }).map((message, index) => (
            <ErrorCard.Text key={`error-${index}`}>{message}</ErrorCard.Text>
          ))}
        </ErrorCard>
      );
  }
};

const getErrorMessage = ({
  formErrors,
}: Pick<Props, 'formErrors'>): string[] => {
  const errorTexts = [];
  if (formErrors.tag) {
    errorTexts.push(getCopy('campaigns__validation_error__tag'));
  }
  if (formErrors.image?.type === 'required') {
    errorTexts.push(getCopy('campaigns__validation_error__image'));
  }
  if (
    formErrors.dates?.type === 'required' &&
    formErrors.dates.message?.includes('attach')
  ) {
    errorTexts.push(getCopy('campaigns__validation_error__attach_date'));
  }
  if (
    formErrors.dates?.type === 'required' &&
    formErrors.dates.message?.includes('active')
  ) {
    errorTexts.push(getCopy('campaigns__validation_error__active_date'));
  }
  if (
    formErrors.isSegmentationJobOngoing?.type === 'segmentationStatusIsOngoing'
  ) {
    errorTexts.push(
      getCopy('campaigns__publish_while_segmentation_job_in_progress')
    );
  }
  if (errorTexts.length === 0) {
    errorTexts.push(getCopy('campaigns__editor_form_error'));
  }
  return errorTexts;
};

const StyledAnimatedItem = styled(AnimatedItem)({
  marginTop: 0,
});
