import {
  ColorPicker,
  FieldSet,
  Flex,
  Margin,
  Message,
  TextField,
  styled,
} from '@kivra/react-components';
import { TextColorIcon } from '@kivra/react-components/icons';
import { pick } from '@kivra/sdk/common';
import type {
  BannerElement,
  ButtonOptions,
  TextOptions,
} from '@sender-portal-fe/util-shared/src/sdk/campaigns/types/bannerConfig';
import React from 'react';
import { VisibilityToggle } from '../VisibilityToggle';
import { AlignmentSelector } from './AlignmentSelector';
import { FontSelector } from './FontSelector';
import { FontSizeControl } from './FontSizeControl';
import { StyleSelector } from './StyleSelector';
import { Textarea } from './Textarea';

interface Props<Options> {
  title: string;
  onChange: (config: BannerElement<Options>) => void;
  config: BannerElement<Options>;
  rows: number;
}

export function BannerText<
  Options extends TextOptions = TextOptions | ButtonOptions,
>({ onChange, config, title, rows }: Props<Options>): React.JSX.Element {
  const setValue = (value: string): void => {
    onChange({
      ...config,
      value: value.substring(0, config.options.maxChars),
    });
  };
  const setOption = (
    options: Partial<TextOptions> | Partial<ButtonOptions>
  ): void =>
    onChange({ ...config, options: { ...config.options, ...options } });

  const characterCount = `${config.value.length} / ${config.options.maxChars}`;
  const isMaxLength = config.value.length >= config.options.maxChars;

  return (
    <FieldSet
      title={title}
      endAdornment={
        <VisibilityToggle
          isVisible={config.options.visible}
          onClick={visible => setOption({ visible })}
        />
      }
    >
      {rows > 1 ? (
        <>
          <Textarea
            value={config.value}
            onChange={e => setValue(e.target.value)}
            rows={rows}
          />
          <Message
            message={characterCount}
            severity={isMaxLength ? 'warning' : 'info'}
            size="small"
          />
        </>
      ) : (
        <TextField
          size="small"
          value={config.value}
          onChange={e => setValue(e.target.value)}
          helpText={characterCount}
          warningMessage={isMaxLength ? characterCount : undefined}
        />
      )}
      <Flex direction="column" alignItems="flex-end">
        <Flex alignItems="center">
          <Margin right={16}>
            <FontSelector
              font={config.options.font}
              onChange={font => setOption({ font })}
            />
          </Margin>
          <AlignmentSelector
            alignment={config.options.alignment}
            onChange={alignment => setOption({ alignment })}
          />
        </Flex>
        <Flex alignItems="center">
          <FontSizeControlWrapper>
            <FontSizeControl
              size={config.options.size}
              onChange={size => setOption({ size })}
            />
          </FontSizeControlWrapper>
          <StyleSelector
            {...pick(config.options, ['bold', 'italic', 'underline'])}
            onChange={styles => setOption(styles)}
          />
          <ColorPicker
            color={config.options.color}
            onChange={color => setOption({ color })}
            component={({ color }) => (
              <div
                style={{
                  cursor: 'pointer',
                  padding: 6,
                  lineHeight: 0,
                  margin: 2,
                }}
              >
                <TextColorIcon size={16} _color={color} />
              </div>
            )}
          />
          {isButton(config) && (
            <ColorPicker
              color={config.options.backgroundColor}
              onChange={color => setOption({ backgroundColor: color })}
            />
          )}
        </Flex>
      </Flex>
    </FieldSet>
  );
}

const isButton = (
  config: BannerElement<TextOptions | ButtonOptions>
): config is BannerElement<ButtonOptions> =>
  'backgroundColor' in config.options;

const FontSizeControlWrapper = styled.div({
  position: 'relative',
  height: 28,
  display: 'flex',
  alignItems: 'end',
  marginRight: '$spacing-12',
});
