import { Body, Caption, Link, css, styled } from '@kivra/react-components';
import { OpenExternallyIcon } from '@kivra/react-components/icons';
import type { Campaign } from '@sender-portal-fe/util-shared/src/sdk/campaigns/types/campaign';
import React from 'react';
import { capitalize } from '../util/capitalize';
import { getCopy } from '../util/copy';
import { pluralDocumentTypeCopy } from '../util/copyKeys';
import { getTimePeriodText, getUpdatedAtString } from '../util/dates';

type Props = {
  campaign: Campaign;
};

export const CampaignReadonlyDetails = ({
  campaign,
}: Props): React.JSX.Element => {
  const shouldShowPostalSpecificFields = campaign.documentType !== 'receipt';

  const isTagSegmentation = Boolean(campaign.tag);

  return (
    <>
      {isTagSegmentation && <DetailPair title="Tag" value={campaign.tag} />}
      <DetailPair title={getCopy('campaigns__title')} value={campaign.title} />
      {shouldShowPostalSpecificFields ||
        (!isTagSegmentation && (
          <DetailPair
            title={getCopy('campaigns__document_type')}
            value={capitalize(campaign.documentType)}
          />
        ))}
      {shouldShowPostalSpecificFields && (
        <DetailPair title="Position" value={capitalize(campaign.position)} />
      )}
      <DetailPair
        title={getCopy('campaigns__uploaded_content')}
        value={campaign.image?.split('/').reverse()[0]}
      />
      {!isTagSegmentation && (
        <DetailPair
          title={getCopy('campaigns__shown_on_document_sent', {
            documentType: pluralDocumentTypeCopy(
              campaign.documentType
            ).toLowerCase(),
          })}
          value={getTimePeriodText(campaign.attachFrom, campaign.attachTo)}
        />
      )}
      <DetailPair
        title={getCopy('campaigns__visible')}
        value={getTimePeriodText(campaign.activeFrom, campaign.activeTo)}
      />
      <DetailPair
        title={getCopy('campaigns__link')}
        value={
          campaign.useSso || campaign.destinationUrl ? (
            <>
              {campaign.useSso && (
                <div>{getCopy('campaigns__sso_enabled')}</div>
              )}
              {campaign.destinationUrl && (
                <Link
                  to={campaign.destinationUrl}
                  color="$text-secondary"
                  hideIcon
                  className={css({
                    display: 'flex',
                    alignItems: 'center',
                    gap: '$spacing-4',
                  })}
                >
                  <OpenExternallyIcon size={16} />
                  <span>{campaign.destinationUrl}</span>
                </Link>
              )}
            </>
          ) : (
            '-'
          )
        }
      />
      {shouldShowPostalSpecificFields && !isTagSegmentation && (
        <DetailPair
          title={getCopy('campaigns__target_group')}
          value={capitalize(campaign.targetGroup)}
        />
      )}
      <DetailPair
        title={getCopy(
          campaign.clientStatus === 'draft'
            ? 'campaigns__updated_at'
            : 'campaigns__published_at'
        )}
        value={getUpdatedAtString(campaign.updatedAt, getCopy)}
      />
      {shouldShowPostalSpecificFields && !isTagSegmentation && (
        <DetailPair
          title={getCopy('campaigns__segmentation')}
          value={campaign.segmentation?.filename}
        />
      )}
      {shouldShowPostalSpecificFields &&
      !isTagSegmentation &&
      campaign.segmentation?.rows &&
      campaign.segmentation.rows > 0 ? (
        <DetailMargin>
          <Body size="small" color="$text-secondary">
            {`${campaign.segmentation.rows} ${getCopy(
              'campaigns__personal_or_org_numbers'
            )}`}
          </Body>
        </DetailMargin>
      ) : null}
      <DetailPair title={getCopy('campaigns__id')} value={campaign.id} />
    </>
  );
};

const DetailPair = ({
  title,
  value,
}: {
  title: string;
  value?: React.JSX.Element | string | null;
}): React.JSX.Element => (
  <DetailMargin>
    <Caption>{title}</Caption>
    <Body size="small" color="$text-secondary">
      {value || '-'}
    </Body>
  </DetailMargin>
);

const DetailMargin = styled('div')({
  wordBreak: 'break-word',
});
