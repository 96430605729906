import React from 'react';
import { useHistory, type RouteComponentProps } from 'react-router-dom';
import { captureException } from '@kivra/sdk/log';
import { useCampaign } from '../../context/campaignContext';
import { CampaignProvider } from '../../context/campaignProvider';
import { useCreateSenderRoute, useSender } from '../../context/senderContext';
import { useCurrentOrganization } from '../../context/sendersContext';
import { goTo, type CampaignPageType } from '../../routes/history';
import { CampaignDetails } from '../details/CampaignDetails';
import { CampaignEditor } from '../editor/CampaignEditor';
import type { CampaignType } from '../../util/campaignType';

export interface ParamsCampaignId {
  campaignId: string;
  pageType: CampaignPageType;
  campaignType?: CampaignType;
}

export const CampaignPage = ({
  match,
}: RouteComponentProps<ParamsCampaignId>): React.JSX.Element => {
  const { organizationId } = useCurrentOrganization();
  const history = useHistory();
  const { campaignId, pageType, campaignType } = match.params;
  const { key: senderKey } = useSender();
  const getSenderRoute = useCreateSenderRoute();
  if (campaignId === 'new' && !campaignType) {
    captureException(
      new Error('Campaign type is required when creating a new campaign')
    );
    goTo(getSenderRoute({ id: 'ongoing-and-upcoming' }));
  }

  return (
    <CampaignProvider
      id={campaignId}
      campaignType={campaignType}
      organizationKey={organizationId}
      senderKey={senderKey}
      onCampaignUpdate={({ id }) => {
        if (id !== campaignId) {
          history.replace(
            getSenderRoute({ id: 'campaign', campaignId: id, pageType: 'edit' })
          );
        }
      }}
    >
      <CampaignView pageType={pageType} />
    </CampaignProvider>
  );
};

const CampaignView = ({
  pageType,
}: {
  pageType: CampaignPageType;
}): React.JSX.Element => {
  const { campaign } = useCampaign(false);
  const shouldShowEditor =
    !campaign || (pageType === 'edit' && campaign.clientStatus !== 'previous');
  return shouldShowEditor ? <CampaignEditor /> : <CampaignDetails />;
};
