import { styled, isPropValid, StyleException } from '@kivra/react-components';
import type { ImgHTMLAttributes } from 'react';

const sizes = {
  small: 32,
  medium: 41,
};

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  size?: keyof typeof sizes;
}

export const UserImage = styled('img', {
  forwardProp: prop => isPropValid(prop),
})<Props>(({ size = 'medium' }: Props) => ({
  width: sizes[size],
  height: sizes[size],
  borderRadius: StyleException('100%'),
  marginRight: '$spacing-10',
}));
